<script setup lang="ts">
import {onMounted, type PropType, ref, watch} from 'vue';
import {deleteKpi, listKpis, listOrganizations, updateKpi} from '@/base/callBackend';
import {
  CabKPI,
  type CabOrganization,
  OnderwerpEnum,
  PrognoseSchaalEnum,
  uuidOf,
  WaardeTypeEnum,
} from '@/base/frontendModel';
// noinspection ES6UnusedImports - usages in <template> are not properly detected!
import {KPI_CHANGE_EMIT, KPI_REMOVE_EMIT} from '@/base/cabConstants';
import KPIStrip from '@/views/admin/KPIStrip.vue';
import PlusButton from '@/views/general/PlusButton.vue';
import {KpiSelectEnum} from '@/base/cabRouter';
import {ElSkeleton, ElSkeletonItem} from 'element-plus';

const props = defineProps({
  kpiSelect: {
    type    : Number as PropType<KpiSelectEnum>,
    required: true,
  },
});

const loading           = ref(true);
const allOrganizations  = ref<CabOrganization[]>([]);
const allKPIs           = ref<CabKPI[]>([]);
const allMilestones     = ref<CabKPI[]>([]);
const allNonMilesotones = ref<CabKPI[]>([]);

onMounted(async () => {
  allOrganizations.value = await listOrganizations();
  allKPIs.value          = await listKpis();
  loading.value          = false;
});

watch(allKPIs, () => {
  allMilestones.value     = allKPIs.value.filter(k => k.isMijlpaal());
  allNonMilesotones.value = allKPIs.value.filter(k => !k.isMijlpaal());
});

//=====================================================================================
function doingMilestones(): boolean {
  return props.kpiSelect === KpiSelectEnum.onlyMilestones;
}

async function onKPIAdd() {
  const newKPI: CabKPI = new CabKPI();
  if (doingMilestones()) {
    newKPI.onderwerp           = OnderwerpEnum.mijlpaal;
    newKPI.waardeType          = undefined;
    newKPI.prognoseSchaal      = PrognoseSchaalEnum.once;
    newKPI.hasDoelwaarde       = false;
    newKPI.askKpiStatus        = false;
    newKPI.isCalculatedValue   = false;
    newKPI.hasUitkeringsbedrag = true;
  } else {
    newKPI.onderwerp           = OnderwerpEnum.adherentie;
    newKPI.waardeType          = WaardeTypeEnum.getal;
    newKPI.prognoseSchaal      = PrognoseSchaalEnum.none;
    newKPI.hasDoelwaarde       = false;
    newKPI.askKpiStatus        = false;
    newKPI.isCalculatedValue   = false;
    newKPI.hasUitkeringsbedrag = false;
  }
  await updateKpi(newKPI);
  allKPIs.value = [newKPI, ...allKPIs.value];
}

async function onKPIRemove(kpi: CabKPI) {
  await deleteKpi(kpi);
  allKPIs.value = allKPIs.value.filter(k => uuidOf(k) !== uuidOf(kpi));
}

async function onKPIChange(kpi: CabKPI) {
  const uuid = uuidOf(kpi);
  await updateKpi(kpi);
  allKPIs.value = allKPIs.value.map((u) => (uuidOf(u) === uuid ? kpi : u));
}
</script>

<template>
  <div class="flex flex-col flex-1 mx-28 my-5">
    <div class="flex flex-row flex-1 mb-1">
      <PlusButton @click="onKPIAdd()"/>
      <div class="text-2xl flex-1 ml-2" v-if="doingMilestones()">
        Mijlpalen
      </div>
      <div class="text-2xl flex-1 ml-2" v-else>
        KPIs
      </div>
    </div>
    <div v-if="loading">
      <el-skeleton :rows="5" animated :count="5">
        <template #template>
          <el-skeleton-item class="h-[69px] m-0.5"/>
        </template>
      </el-skeleton>
    </div>
    <KPIStrip
        v-else
        v-for="kpi in (doingMilestones()?allMilestones:allNonMilesotones)"
        :key="uuidOf(kpi)"
        :kpi="kpi"
        :allOrganizations="allOrganizations"
        v-on:[KPI_REMOVE_EMIT]="onKPIRemove"
        v-on:[KPI_CHANGE_EMIT]="onKPIChange"
    />
  </div>
</template>
