<script setup lang="ts">
import {onMounted, ref} from 'vue';
import {deleteUser, listOrganizations, listUsers, syncUsers, updateUser} from '@/base/callBackend';
import {type CabOrganization, CabUser, uuidOf} from '@/base/frontendModel';
// noinspection ES6UnusedImports - usages in <template> are not properly detected!
import {USER_CHANGE_EMIT, USER_REMOVE_EMIT} from '@/base/cabConstants';
import UserStrip from '@/views/admin/UserStrip.vue';
import PlusButton from '@/views/general/PlusButton.vue';
import SyncButton from '@/views/general/SyncButton.vue';
import {ElMessage} from 'element-plus';

const allUsers         = ref<CabUser[]>([]);
const allOrganizations = ref<CabOrganization[]>([]);

onMounted(async () => {
  allUsers.value         = await listUsers();
  allOrganizations.value = await listOrganizations();
});

//=====================================================================================
async function onUserRemove(user: CabUser) {
  await deleteUser(user);
  allUsers.value = allUsers.value.filter((u) => uuidOf(u) !== uuidOf(user));
}

async function onUserAdd() {
  const newUser = new CabUser();
  await updateUser(newUser);
  allUsers.value = [newUser, ...allUsers.value];
}

async function onUserChange(user: CabUser) {
  await updateUser(user);
  allUsers.value = allUsers.value.map((u) =>
      uuidOf(u) === uuidOf(user) ? user : u,
  );
}

async function onUserSync() {
  await syncUsers();
  ElMessage({
    message: 'Rapporteurs zijn gesynced met de inlog administratie',
    type   : 'success',
  });
}

//=====================================================================================
</script>

<template>
  <div class="flex flex-col flex-1 mx-28 my-5">
    <div class="flex flex-row flex-1 mb-1">
      <PlusButton @click="onUserAdd()"/>
      <SyncButton @click="onUserSync()"/>
      <div class="text-2xl flex-1 ml-2">Rapporteurs</div>
    </div>
    <UserStrip
        v-for="user in allUsers"
        :key="uuidOf(user)"
        :user="user"
        :allOrganizations="allOrganizations"
        v-on:[USER_REMOVE_EMIT]="onUserRemove"
        v-on:[USER_CHANGE_EMIT]="onUserChange"
    />
  </div>
</template>
