<script setup lang="ts">
import {onMounted, ref} from 'vue';
import {deleteOrganization, listOrganizations, listThemes, updateOrganization} from '@/base/callBackend';
import {CabOrganization, CabTheme, uuidOf} from '@/base/frontendModel';
// noinspection ES6UnusedImports - usages in <template> are not properly detected!
import {ORGANIZATION_CHANGE_EMIT, ORGANIZATION_REMOVE_EMIT} from '@/base/cabConstants';
import OrganizationStrip from '@/views/admin/OrganizationStrip.vue';
import PlusButton from '@/views/general/PlusButton.vue';

const allOrganizations = ref<CabOrganization[]>([]);
const allThemes        = ref<CabTheme[]>([]);

onMounted(async () => {
  allOrganizations.value = await listOrganizations();
  allThemes.value        = await listThemes();
});

//=====================================================================================
async function onOrganizationRemove(organization: CabOrganization) {
  await deleteOrganization(organization);
  allOrganizations.value = allOrganizations.value.filter((o) => uuidOf(o) !== uuidOf(organization));
}

async function onOrganizationAdd() {
  const newOrganization = new CabOrganization();
  await updateOrganization(newOrganization);
  allOrganizations.value = [newOrganization, ...allOrganizations.value];
}

async function onOrganizationChange(organization: CabOrganization) {
  const uuid = uuidOf(organization);
  await updateOrganization(organization);
  allOrganizations.value = allOrganizations.value.map((o) =>
      uuidOf(o) === uuid ? organization : o,
  );
}

//=====================================================================================
</script>

<template>
  <div class="flex flex-col flex-1 mx-28 my-5">
    <div class="flex flex-row flex-1 mb-1">
      <PlusButton @click="onOrganizationAdd()"/>
      <div class="text-2xl flex-1 ml-2">Instellingen</div>
    </div>
    <OrganizationStrip
        v-for="organization in allOrganizations"
        :key="uuidOf(organization)"
        :organization="organization"
        :allThemes="allThemes"
        v-on:[ORGANIZATION_REMOVE_EMIT]="onOrganizationRemove"
        v-on:[ORGANIZATION_CHANGE_EMIT]="onOrganizationChange"
    />
  </div>
</template>
