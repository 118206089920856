<script setup lang="ts">
import {nextTick, type PropType, ref, toRaw, watch} from 'vue';
import {ORGANIZATION_CHANGE_EMIT, ORGANIZATION_REMOVE_EMIT} from '@/base/cabConstants';
import {CabObj, type CabOrganization, CabTheme, uuidOf} from '@/base/frontendModel';
import DeleteButton from '@/views/general/DeleteButton.vue';
import {ElCascader} from 'element-plus';

const emits = defineEmits([ORGANIZATION_REMOVE_EMIT, ORGANIZATION_CHANGE_EMIT]);
const props = defineProps({
  organization: {
    type    : Object as PropType<CabOrganization>,
    required: true,
  },
  allThemes   : {
    type    : Object as PropType<CabTheme[]>,
    required: true,
  },
});

const organization     = ref<CabOrganization>(props.organization);
const themeCascaderRef = ref();
const allThemes        = ref();
const selectedThemes   = ref();
const nameField        = ref<HTMLInputElement | null>(null);
const nameInfo         = {
  field    : nameField,
  isEditing: ref<boolean>(false),
};

watch(
    () => props.allThemes,
    (newThemes) => {
      allThemes.value = makeCascaderOptions(newThemes);
    },
    {immediate: true},
);

watch(
    () => props.organization,
    (org) => {
      selectedThemes.value = org?.themes.map((t) => [uuidOf(t)]);
    },
    {immediate: true},
);

function makeCascaderOptions<T extends CabObj>(list: T[]): any {
  return list.map((o) => ({
    label: (o as any).name,
    value: uuidOf(o),
  }));
}

//==========================================
function startEditing(info: any) {
  info.isEditing.value = true;
  nextTick(() => nextTick(() => info.field.value.focus()));
}

function stopEditing(info: any) {
  info.isEditing.value = false;
  info.field.value.blur();
}

function onDelete() {
  emits(ORGANIZATION_REMOVE_EMIT, organization.value);
}

function onChangeThemes() {
  const rawSelected         = toRaw(selectedThemes.value).map((l: any) => toRaw(l)[0]);
  organization.value.themes = props.allThemes?.filter((theme) =>
      rawSelected.includes(uuidOf(theme)),
  );
  onChange();
}

function onChange() {
  emits(ORGANIZATION_CHANGE_EMIT, organization.value);
}

function dynInputClass(info: any) {
  return info.isEditing.value
         ? `bg-white border-gray-300 rounded-md shadow-xl`
         : `bg-yellow-100`;
}
</script>

<template>
  <div
      class="flex flex-row items-center w-full font-medium m-0.5 p-1 bg-yellow-100 text-gray-900"
  >
    <div class="w-96 px-3 py-1" title="instelling naam">
      <input
          class="mt-1 mr-1 px-2 py-1 border-1 w-full"
          :class="dynInputClass(nameInfo)"
          type="text"
          ref="nameField"
          v-model="organization.name"
          @input="onChange"
          @click="startEditing(nameInfo)"
          @focus="startEditing(nameInfo)"
          @keyup.enter="stopEditing(nameInfo)"
          @blur="stopEditing(nameInfo)"
          placeholder="name"
      />
    </div>

    <div class="text-xs text-gray-400">
      themas:
    </div>

    <div class="p-1 flex-1" title="themas voor deze instelling">
      <el-cascader
          class="w-full"
          ref="themeCascaderRef"
          v-model="selectedThemes"
          :options="allThemes"
          :props="{ multiple: true }"
          clearable
          placeholder="selecteer themas"
          @change="onChangeThemes"
      />
    </div>

    <DeleteButton @click="onDelete()"/>
  </div>
</template>
