<script setup lang="ts">
import {nextTick, onMounted, ref, watch} from 'vue';
import {listMyReportItems, updateReportItem} from '@/base/callBackend';
import {
  cabCompare,
  CabObj,
  CabOrganization,
  CabReportItem,
  isCabObject,
  OnderwerpEnum, OnderwerpEnum_compare,
  StatusEnum,
  uuidOf,
} from '@/base/frontendModel';
import {ElCheckbox, ElMessage, ElOption, ElSelect, ElSkeleton, ElSkeletonItem} from 'element-plus';
import {IdMapMap} from '@/base/idMap';
import ReportOnderwerpGroup from '@/views/report/ReportOnderwerpGroup.vue';

//===============================================================================================================================================
const REPORT_SELECTED_ORG_UUID: string    = 'REPORT_SELECTED_ORG_UUID';
const REPORT_SHOW_SUBMITTED: string       = 'REPORT_SHOW_SUBMITTED';
const PERIOD_TO_SHOW_OMISIONS_SEC: number = 30;

//===============================================================================================================================================
const reloadTrigger            = ref(0);
const loadInProgress           = ref(true);
const all                      = ref<CabReportItem[]>([]);
const showSubmitted            = ref(false);
const allViewable              = ref<CabReportItem[]>([]);
const allViewableMapmap        = ref<IdMapMap<CabOrganization, OnderwerpEnum, CabReportItem>>();
const allViewableOrganizations = ref<CabOrganization[]>([]);
const selectedOrganizationUuid = ref<string>('');
const selectedOrganization     = ref<CabOrganization>();
const allVisible               = ref<CabReportItem[]>([]);
const allVisibleOnderwerpen    = ref<OnderwerpEnum[]>([]);
const flagOmissions            = ref(false);
//
const anySubmitted             = ref<boolean>(false);
const anyUnsubmitted           = ref<boolean>(false);
const anySubmittedViewable     = ref<boolean>(false);
const anyUnsubmittedViewable   = ref<boolean>(false);
const anySubmittedVisible      = ref<boolean>(false);
const anyUnsubmittedVisible    = ref<boolean>(false);

//===============================================================================================================================================
onMounted(async () => {
  showSubmitted.value            = JSON.parse(localStorage.getItem(REPORT_SHOW_SUBMITTED) || 'false');
  selectedOrganizationUuid.value = localStorage.getItem(REPORT_SELECTED_ORG_UUID) || '';
  triggerReload();
});
watch(showSubmitted, () => {
  localStorage.setItem('REPORT_SHOW_SUBMITTED', JSON.stringify(showSubmitted.value));
});
watch(selectedOrganizationUuid, () => {
  localStorage.setItem('REPORT_SELECTED_OR_UUID', selectedOrganizationUuid.value);
});

//===============================================================================================================================================
watch(reloadTrigger, async () => {
  loadInProgress.value = true;
  all.value            = await listMyReportItems();
});
watch([all, showSubmitted], () => {
  anySubmitted.value           = some(all.value, submittedSet());
  anyUnsubmitted.value         = some(all.value, unsubmittedSet());
  allViewable.value            = filter(all.value, visibleSet());
  allViewableMapmap.value      = listToMapmap(allViewable.value);
  anySubmittedViewable.value   = some(allViewable.value, submittedSet());
  anyUnsubmittedViewable.value = some(allViewable.value, unsubmittedSet());

  allViewableOrganizations.value = Array.from(allViewableMapmap.value.keys());
  // select the first organization if the currently selected does not exist:
  if (!allViewableOrganizations.value.some(o => uuidOf(o) === selectedOrganizationUuid.value)) {
    selectedOrganizationUuid.value = allViewableOrganizations.value.length ? uuidOf(allViewableOrganizations.value[0]) : '';
  }
});
watch(selectedOrganizationUuid, () => {
  selectedOrganization.value = allViewableOrganizations.value.find(o => uuidOf(o) === selectedOrganizationUuid.value) ?? allViewableOrganizations.value[0];
});
watch([allViewableMapmap, selectedOrganization], () => {
  if (selectedOrganization.value) {
    allVisible.value            = allViewableMapmap.value!.get(selectedOrganization.value)?.values() ?? [];
    allVisibleOnderwerpen.value = allViewableMapmap.value!.get(selectedOrganization.value)?.keys() ?? [];
    anySubmittedVisible.value   = some(allVisible.value, submittedSet());
    anyUnsubmittedVisible.value = some(allVisible.value, unsubmittedSet());
  }
  loadInProgress.value = false;
});


//===============================================================================================================================================
function triggerReload() {
  loadInProgress.value = true;
  nextTick(() => {
    reloadTrigger.value++;
  });
}

function mixedUuid(o: OnderwerpEnum | CabObj) {
  if (typeof o === 'number') {
    return `${o}`;
  } else if (isCabObject(o)) {
    return uuidOf(o);
  } else {
    throw new Error(`dont know how to get uuid of ${o} ${typeof o}`);
  }
}

function listToMapmap(l: CabReportItem[]): IdMapMap<CabOrganization, OnderwerpEnum, CabReportItem> {
  const m = new IdMapMap<CabOrganization, OnderwerpEnum, CabReportItem>(mixedUuid, cabCompare, OnderwerpEnum_compare, cabCompare);
  for (const ri of l) {
    m.set(ri.organization[0], ri.kpi[0].onderwerp!, ri);
  }
  return m;
}

//===============================================================================================================================================
function filter(l: CabReportItem[], set: Set<StatusEnum>): CabReportItem[] {
  return l.filter(ri => set.has(ri.status!));
}

function some(l: CabReportItem[], set: Set<StatusEnum>) {
  return l.some(ri => set.has(ri.status!));
}

function visibleSet(): Set<StatusEnum> {
  if (showSubmitted.value) {
    return new Set([...unsubmittedSet(), ...submittedSet()]);
  } else {
    return unsubmittedSet();
  }
}

function unsubmittedSet(): Set<StatusEnum> {
  return new Set([StatusEnum.open, StatusEnum.overdue /*StatusEnum.submitted, StatusEnum.accepted, StatusEnum.future*/]);
}

function submittedSet(): Set<StatusEnum> {
  return new Set([/*StatusEnum.open, StatusEnum.overdue,*/ StatusEnum.submitted, StatusEnum.accepted /*StatusEnum.future*/]);
}

//===============================================================================================================================================
function yeahWeAreDone() {
  return allViewable.value.length == 0;
}

//===============================================================================================================================================
function allVisibleSubmittable(): boolean {
  return allVisible.value.every(ri => ri.isSubmittable());
}

async function onSubmit() {
  if (allVisibleSubmittable()) {
    for (const ri of allVisible.value) {
      ri.status = StatusEnum.submitted;
      await updateReportItem(ri as CabReportItem);
    }
    triggerReload();
  } else {
    ElMessage({
      message: `je hebt nog niet alles ingevuld...`,
      type   : 'error',
    });

    flagOmissions.value = true;
    setTimeout(() => {
      flagOmissions.value = false;
    }, PERIOD_TO_SHOW_OMISIONS_SEC * 1000);
  }
}

function allVisibleReportItemsOfOnderwerp(o: OnderwerpEnum): CabReportItem[] {
  return allViewableMapmap.value?.getget(selectedOrganization.value!, o)!;
}

function submitChoice(submittedText: string, unsubmittedText: string, bothText: string): string {
  if (anySubmittedVisible.value && anyUnsubmittedVisible.value) {
    return bothText;
  }
  if (anySubmittedVisible.value) {
    return submittedText;
  }
  if (anyUnsubmittedVisible.value) {
    return unsubmittedText;
  }
  return '???';
}
</script>
//===============================================================================================================================================
//===============================================================================================================================================
//===============================================================================================================================================
<template>

  <div v-if="loadInProgress" class="flex flex-col flex-1 mx-28">
    <div class="flex flex-row flex-1 items-center justify-center mt-10 mb-5">
      <div class="text-xl text-center w-full">
        Loading...
      </div>
    </div>
    <el-skeleton animated :count="5" class="flex flex-col">
      <template #template>
        <el-skeleton-item class="w-full h-8 my-1"/>
      </template>
    </el-skeleton>
  </div>

  <div v-else class="flex flex-col flex-1 mx-28">
    <div class="flex flex-row flex-1 items-center justify-center mt-10 mb-5">

      <div v-if="yeahWeAreDone()" class="text-xl text-center w-full">
        Fijn, alle rapportages voor deze periode zijn ingediend!
      </div>

      <div v-else class="flex flex-row items-center justify-left w-full h-full">
        <div class="text-base mr-1">
          Voor
        </div>
        <el-select v-model="selectedOrganizationUuid" class="w-56 mx-1" v-if="1 < allViewableOrganizations.length">
          <el-option
              v-for="org in allViewableOrganizations"
              :key="uuidOf(org)"
              :value="uuidOf(org)"
              :label="org.name"
          />
        </el-select>
        <div v-else class="mx-1 font-extrabold">
          {{ allViewableOrganizations[0].name }}
        </div>
        <div class="text-base mx-1">
          {{ submitChoice('rapporteerde', 'rapporteer', 'rapporteer/rapporteerde') }} ik het volgende:
        </div>

      </div>
      <div class="flex-1">
        <!--spacer-->
      </div>

      <button
          v-if="anyUnsubmittedViewable"
          class="bg-green-600 hover:bg-green-700 text-white text-sm font-bold py-1 px-8 m-2 rounded cursor-pointer whitespace-nowrap"
          @click="onSubmit"
      >
        Alles Indienen
      </button>

      <el-checkbox
          v-if="anySubmitted"
          class="mr-2"
          v-model="showSubmitted"
          label="toon ingediende"
          border
      />

    </div>

    <div class="flex-grow">
      <ReportOnderwerpGroup
          v-for="o in allVisibleOnderwerpen"
          :key="o"
          :onderwerp="o"
          :reportItems="allVisibleReportItemsOfOnderwerp(o)"
          :flagOmissions="flagOmissions"
      />
    </div>
  </div>
</template>
