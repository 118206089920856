<script setup lang="ts">
import {nextTick, type PropType, ref, toRaw, watch} from 'vue';
import {USER_CHANGE_EMIT, USER_REMOVE_EMIT} from '@/base/cabConstants';
import {type CabOrganization, CabTheme, type CabUser, uuidOf} from '@/base/frontendModel';
import DeleteButton from '@/views/general/DeleteButton.vue';
import {type CascaderOption, ElCascader} from 'element-plus';

const emits = defineEmits([USER_REMOVE_EMIT, USER_CHANGE_EMIT]);
const props = defineProps({
  user            : {
    type    : Object as PropType<CabUser>,
    required: true,
  },
  allOrganizations: {
    type    : Object as PropType<CabOrganization[]>,
    required: true,
  },
});

const user             = ref<CabUser>(props.user);
const nameField        = ref<HTMLInputElement | null>(null);
const emailField       = ref<HTMLInputElement | null>(null);
const orgThemeOptions  = ref();
const orgThemeSelected = ref();

const nameInfo  = {
  field    : nameField,
  isEditing: ref<boolean>(false),
};
const emailInfo = {
  field    : emailField,
  isEditing: ref<boolean>(false),
};

watch(
    () => props.user,
    () => {
      orgThemeSelected.value = props.user?.getOrgThemeCombiUuids();
    },
    {immediate: true},
);

watch(
    () => props.allOrganizations,
    () => {
      orgThemeOptions.value = makeCascaderOptions(props.allOrganizations);
    },
    {immediate: true},
);

function makeCascaderOptions(allOrganizations: CabOrganization[]): CascaderOption[] {
  return allOrganizations.map((o) => ({
    label   : o.name,
    value   : uuidOf(o),
    children: o.themes.map((t) => ({
      label: t.name,
      value: uuidOf(t),
    })),
  }));
}

//==========================================
function startEditing(info: any) {
  info.isEditing.value = true;
  nextTick(() => nextTick(() => info.field.value.focus()));
}

function stopEditing(info: any) {
  info.isEditing.value = false;
  info.field.value.blur();
}

//==========================================
function onDelete() {
  emits(USER_REMOVE_EMIT, user.value);
}

function onChangeOrgTheme() {
  const rawSelected: string[][] = toRaw(orgThemeSelected.value).map(
      (path: string[]) => toRaw(path),
  );
  const orgs: CabOrganization[] = [];
  const thms: CabTheme[]        = [];
  rawSelected.forEach(([oUuid, tUuid]) => {
    const org = props.allOrganizations.find((o) => uuidOf(o) === oUuid)!;
    const thm = org.themes.find((t) => uuidOf(t) === tUuid)!;
    orgs.push(org);
    thms.push(thm);
  });
  Object.assign(user.value, {
    organizations: orgs,
    themes       : thms,
  });
  onChange();
}

function onChange() {
  emits(USER_CHANGE_EMIT, user.value);
}

function dynInputClass(info: any) {
  return info.isEditing.value
         ? `bg-whiteborder-gray-300 rounded-md shadow-xl`
         : `bg-yellow-100`;
}
</script>

<template>
  <div
      class="flex flex-row items-center w-full font-medium m-0.5 p-1 bg-yellow-100 text-gray-900"
  >
    <div class="w-60 p-1" title="naam van de rapporteur">
      <input
          class="mt-1 mr-1 px-2 py-1 border-1 w-full"
          :class="dynInputClass(nameInfo)"
          type="text"
          ref="nameField"
          v-model="user.name"
          @input="onChange"
          @click="startEditing(nameInfo)"
          @focus="startEditing(nameInfo)"
          @keyup.enter="stopEditing(nameInfo)"
          @blur="stopEditing(nameInfo)"
          placeholder="name"
      />
    </div>

    <div class="w-60 p-1" title="email van de rapporteur">
      <input
          class="mt-1 mr-1 px-2 py-1 border-1 w-full"
          :class="dynInputClass(emailInfo)"
          type="text"
          ref="emailField"
          v-model="user.email"
          @input="onChange"
          @click="startEditing(emailInfo)"
          @focus="startEditing(emailInfo)"
          @keyup.enter="stopEditing(emailInfo)"
          @blur="stopEditing(emailInfo)"
          placeholder="email"
      />
    </div>

    <div class="text-xs text-gray-400">
      instelling/thema:
    </div>
    <el-cascader
        class="flex-1"
        v-model="orgThemeSelected"
        :options="orgThemeOptions"
        :props="{ multiple: true }"
        clearable
        placeholder="Selecteer instelling/themas"
        @change="onChangeOrgTheme"
    />

    <DeleteButton @click="onDelete()"/>
  </div>
</template>
