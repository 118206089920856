<script setup lang="ts">
import {nextTick, type PropType, ref, toRaw, watch} from 'vue';
import {KPI_CHANGE_EMIT, KPI_REMOVE_EMIT} from '@/base/cabConstants';
import {
  CabKPI,
  type CabOrganization,
  CabTheme,
  diffDates,
  diffTimeSpans,
  enum_values, KPITypeEnum_name,
  OnderwerpEnum, OnderwerpEnum_name,
  PeriodEnum, PeriodEnum_name,
  PrognoseSchaalEnum,
  uuidOf, VoorspellingsSchaalEnum_name,
  WaardeTypeEnum, WaardeTypeEnum_name,
} from '@/base/frontendModel';
import DeleteButton from '@/views/general/DeleteButton.vue';
import {type CascaderOption, ElCascader, ElCheckbox, ElDatePicker, ElInput, ElOption, ElSelect} from 'element-plus';
import 'element-plus/dist/index.css';
import ExpandButton from '@/views/general/ExpandButton.vue';
import KpiIcon from '@/views/general/KpiIcon.vue';

const emits = defineEmits([KPI_REMOVE_EMIT, KPI_CHANGE_EMIT]);
const props = defineProps({
  kpi             : {
    type    : Object as PropType<CabKPI>,
    required: true,
  },
  allOrganizations: {
    type    : Object as PropType<CabOrganization[]>,
    required: true,
  },
});

const kpi                   = ref<CabKPI>(props.kpi);
const timeSpanHuman         = ref<Date[] | undefined>(kpi.value.isMijlpaal() ? undefined : timeSpanToHuman(kpi.value.getTimeSpan()));
const eersteMeetMomentHuman = ref<Date | undefined>(kpi.value.isMijlpaal() && kpi.value.getEersteMeetMoment() ? dateToHuman(kpi.value.getEersteMeetMoment()) : undefined);
const expanded              = ref(false);
const nameField             = ref<HTMLInputElement | null>(null);
const descriptionField      = ref<HTMLInputElement | null>(null);
const orgThemeOptions       = ref();
const orgThemeSelected      = ref();

const nameInfo        = {
  field    : nameField,
  isEditing: ref<boolean>(false),
};
const descriptionInfo = {
  field    : descriptionField,
  isEditing: ref<boolean>(false),
};

watch(() => props.kpi,
    () => {
      orgThemeSelected.value = props.kpi?.getOrgThemeCombiUuids();
    },
    {immediate: true},
);

watch(() => props.allOrganizations,
    () => {
      orgThemeOptions.value = makeCascaderOptions(props.allOrganizations);
    },
    {immediate: true},
);

watch(timeSpanHuman,
    (n) => {
      if (!kpi.value.isMijlpaal()) {
        if (!n) {
          if (kpi.value.clearTimeSpan()) {
            onChange();
          }
        } else if (kpi.value.setTimeSpan(timeSpanFromHuman(n))) {
          onChange();
        }
        const newHumanSpan = timeSpanToHuman(kpi.value.getTimeSpan());
        if (!n || diffTimeSpans(n, newHumanSpan)) {
          timeSpanHuman.value = newHumanSpan;
        }
      }
    },
    {immediate: true},
);
watch(eersteMeetMomentHuman,
    (n) => {
      if (kpi.value.isMijlpaal()) {
        if (!n) {
          if (kpi.value.clearEersteMeetMoment()) {
            onChange();
          }
        } else if (kpi.value.setEersteMeetMoment(dateFromHuman(n))) {
          onChange();
        }
        const newHumanDate = dateToHuman(kpi.value.getEersteMeetMoment());
        if (!n || diffDates(n, newHumanDate)) {
          eersteMeetMomentHuman.value = newHumanDate;
        }
      }
    },
    {immediate: true},
);

function makeCascaderOptions(allOrganizations: CabOrganization[]): CascaderOption[] {
  return allOrganizations.map((o) => ({
    label   : o.name,
    value   : uuidOf(o),
    children: o.themes.map((t) => ({
      label: t.name,
      value: uuidOf(t),
    })),
  }));
}

function timeSpanToHuman(v: Date[]): Date[] {
  return [dateToHuman(v[0]), dateToHuman(v[1])];
}

function timeSpanFromHuman(v: Date[]): Date[] {
  if (v.length != 2 || !v[0] || !v[1]) {
    return CabKPI.initialTimeSpan(kpi.value.getReportingPeriod());
  } else {
    return [dateFromHuman(v[0]), dateFromHuman(v[1])];
  }
}

function dateToHuman(v: Date): Date {
  return new Date(v.getUTCFullYear(), v.getUTCMonth());
}

function dateFromHuman(v: Date): Date {
  if (!v) {
    return CabKPI.initialEersteMeetMoment();
  } else {
    return new Date(Date.UTC(v.getFullYear(), v.getMonth()));
  }
}

//==========================================
function startEditing(info: any) {
  info.isEditing.value = true;
  nextTick(() => nextTick(() => info.field.value.focus?.()));
}

function stopEditing(info: any) {
  info.isEditing.value = false;
  info.field.value.blur?.();
}

//==========================================
function onDelete() {
  emits(KPI_REMOVE_EMIT, kpi.value);
}

function onChange() {
  if (kpi.value.isMijlpaal()) {
    kpi.value.hasDoelwaarde = false;
    kpi.value.askKpiStatus = false;
  }
  emits(KPI_CHANGE_EMIT, kpi.value);
}

function onChangeOrgTheme() {
  const rawSelected: string[][] = toRaw(orgThemeSelected.value).map(
      (path: string[]) => toRaw(path),
  );
  const orgs: CabOrganization[] = [];
  const thms: CabTheme[]        = [];
  rawSelected.forEach(([oUuid, tUuid]) => {
    const org = props.allOrganizations.find((o) => uuidOf(o) === oUuid)!;
    const thm = org.themes.find((t) => uuidOf(t) === tUuid)!;
    orgs.push(org);
    thms.push(thm);
  });
  Object.assign(kpi.value, {
    organizations: orgs,
    themes       : thms,
  });
  onChange();
}

function onClickPeriod() {
  kpi.value.setReportingPeriod(kpi.value.getReportingPeriod() === PeriodEnum.Q ? PeriodEnum.H : PeriodEnum.Q);
  timeSpanHuman.value = timeSpanToHuman(kpi.value.getTimeSpan());
  onChange();
}

//==========================================
function periodButtonText(): string {
  return `${PeriodEnum_name(kpi.value.getReportingPeriod())} (${kpi.value.listPeriods().length})`;
}
</script>

<template>
  <div class="flex flex-col items-stretch content-center bg-gray-100 text-gray-900 m-0.5">
    <div class="flex flex-row items-center">
      <ExpandButton v-model="expanded"/>
      <div title="naam" class="flex flex-row items-baseline flex-1">
        <KpiIcon :kpi="kpi"/>
        <div
            class="text-base font-semibold ml-[11px] h-6 w-full"
            v-if="!nameInfo.isEditing.value"
            @click="startEditing(nameInfo)"
        >
          {{ kpi.name }}
        </div>
        <el-input
            v-else
            class="text-base font-semibold"
            v-model="kpi.name"
            ref="nameField"
            @input="onChange"
            @click="startEditing(nameInfo)"
            @focus="startEditing(nameInfo)"
            @blur="stopEditing(nameInfo)"
            placeholder="name"
        />
      </div>

      <div class="p-1" title="meetmoment" v-if="kpi.isMijlpaal()">
        <el-date-picker
            class="flex-shrink"
            v-model="eersteMeetMomentHuman"
            type="month"
            placeholder="kies een kwartaal"
            @change="onChange"
        />
      </div>

      <div class="flex flex-row items-center" v-if="!kpi.isMijlpaal()">
        <div class="text-xs text-gray-400">onderwerp:</div>
        <div class="p-1 w-36" title="onderwerp">
          <el-select
              v-model="kpi.onderwerp"
              placeholder="onderwerp"
              @change="onChange"
          >
            <el-option
                v-for="item in enum_values(OnderwerpEnum).filter( (v:OnderwerpEnum) => v !== OnderwerpEnum.mijlpaal)"
                :key="item"
                :label="OnderwerpEnum_name(item)"
                :value="item"
            />
          </el-select>
        </div>
        <div class="text-xs text-gray-400 ml-3">
          type:
        </div>
        <div class="p-1 text-nowrap w-40 font-bold" title="type">
          {{ KPITypeEnum_name(kpi.getKpiType()) }}
        </div>
      </div>

      <DeleteButton @click="onDelete()" class="flex-0"/>
    </div>

    <div v-if="expanded" class="ml-10">
      <div class="">
        <div class="flex flex-row items-center flex-1 m-1" title="instelling/thema combi's voor deze KPI">
          <div class="text-xs text-gray-400 p-1">
            instelling/thema:
          </div>
          <el-cascader
              class="flex-1"
              v-model="orgThemeSelected"
              :options="orgThemeOptions"
              :props="{ multiple: true }"
              clearable
              placeholder="Selecteer instelling/themas"
              @change="onChangeOrgTheme"
          />
        </div>
      </div>

      <div class="flex flex-row items-center m-1">
        <div class="p-1 flex-1" title="omschrijving">
          <el-input
              v-model="kpi.omschrijving"
              ref="descriptionField"
              @input="onChange"
              @click="startEditing(descriptionInfo)"
              @focus="startEditing(descriptionInfo)"
              @blur="stopEditing(descriptionInfo)"
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 5 }"
              placeholder="omschrijving"
          />
        </div>
      </div>

      <div class="flex flex-row items-center m-1">
        <button
            v-if="!kpi.isMijlpaal()"
            class="text-white text-xs font-bold m-3 py-1 px-2 rounded cursor-pointer w-36 bg-green-600 hover:bg-green-700"
            @click="onClickPeriod"
        >
          {{ periodButtonText() }}
        </button>

        <div class="p-1" title="toepassingsperiode" v-if="!kpi.isMijlpaal()">
          <el-date-picker
              v-model="timeSpanHuman"
              type="monthrange"
              unlink-panels
              range-separator="tot"
              start-placeholder="start maand"
              end-placeholder="eind maand"
              :clearable="false"
          />
        </div>

        <div class="p-1" title="waarde type" v-if="!kpi.isMijlpaal()">
          <el-select
              v-model="kpi.waardeType"
              placeholder="waarde type"
              @change="onChange"
              class="w-40"
          >
            <el-option
                v-for="item in enum_values(WaardeTypeEnum)"
                :key="item"
                :label="WaardeTypeEnum_name(item)"
                :value="item"
            />
          </el-select>
        </div>

        <el-checkbox
            class="mr-2"
            v-if="!kpi.isMijlpaal()"
            v-model="kpi.hasDoelwaarde"
            label="doelwaarde"
            @change="onChange"
            border
        />

        <el-checkbox
            class="mr-2"
            v-if="!kpi.isMijlpaal()"
            v-model="kpi.askKpiStatus"
            label="ask KPI status"
            @change="onChange"
            border
        />

        <el-checkbox
            class="mr-2"
            v-if="!kpi.isMijlpaal()"
            v-model="kpi.hasUitkeringsbedrag"
            label="uitkeringsbedrag"
            @change="onChange"
            border
        />

        <el-checkbox
            class="mr-2"
            v-if="!kpi.isMijlpaal()"
            v-model="kpi.isCalculatedValue"
            label="berekende waarde"
            @change="onChange"
            border
        />

        <div class="p-1" title="prognoseschaal" v-if="!kpi.isMijlpaal()">
          <el-select
              v-model="kpi.prognoseSchaal"
              placeholder="prognoseschaal"
              @change="onChange"
              class="w-40"
          >
            <el-option
                v-for="item in enum_values(PrognoseSchaalEnum)"
                :key="item"
                :label="VoorspellingsSchaalEnum_name(item)"
                :value="item"
            />
          </el-select>
        </div>

      </div>

    </div>
  </div>
</template>
