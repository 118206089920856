// noinspection JSUnusedGlobalSymbols

export class IdMap<K, V> {
    private readonly map: Map<string, { k: K; va: V[] }> = new Map<string, { k: K, va: V[] }>();
    private readonly uuidOf: (o: any) => string;
    private readonly kCompare: (a: K, b: K) => number;
    private readonly vCompare: (a: V, b: V) => number;

    constructor(uuidOf: (o: any) => string, kCompare: (a: K, b: K) => number, vCompare: (a: V, b: V) => number) {
        this.uuidOf   = uuidOf;
        this.kCompare = kCompare;
        this.vCompare = vCompare;
    }

    get(k: K): V[] | undefined {
        return this.map.get(this.uuidOf(k))?.va;
    }

    set(k: K, v: V): void {
        const uuid: string                   = this.uuidOf(k);
        let e: { k: K; va: V[] } | undefined = this.map.get(uuid);
        if (!e) {
            e = {
                k : k,
                va: [],
            };
            this.map.set(uuid, e);
        }
        e.va.push(v);
    }

    keys(): K[] {
        return Array.from(this.map.values())
            .map(e => e.k)
            .sort(this.kCompare);
    }

    values(): V[] {
        return Array.from(this.map.values())
            .map(e => e.va)
            .flat()
            .sort(this.vCompare);
    }

    key(uuid: string) {
        return this.map.get(uuid)?.k;
    }
}

export class IdMapMap<K1, K2, V> {
    private readonly map: Map<string, { k: K1; va: IdMap<K2, V> }> = new Map<string, { k: K1, va: IdMap<K2, V> }>();
    private readonly uuidOf: (o: any) => string;
    private readonly k1Compare: (a: K1, b: K1) => number;
    private readonly k2Compare: (a: K2, b: K2) => number;
    private readonly vCompare: (a: V, b: V) => number;

    constructor(uuidOf: (o: any) => string, k1Compare: (a: K1, b: K1) => number, k2Compare: (a: K2, b: K2) => number, vCompare: (a: V, b: V) => number) {
        this.uuidOf    = uuidOf;
        this.k1Compare = k1Compare;
        this.k2Compare = k2Compare;
        this.vCompare  = vCompare;
    }

    get(k1: K1): IdMap<K2, V> | undefined {
        return this.value(this.uuidOf(k1));
    }

    getget(k1: K1, k2: K2): V[] | undefined {
        return this.getByUuid(this.uuidOf(k1), k2);
    }

    getByUuid(uuid: string, k2: K2): V[] | undefined {
        return this.map.get(uuid)?.va?.get(k2);
    }

    set(k1: K1, k2: K2, v: V) {
        const uuid: string                             = this.uuidOf(k1);
        let e: { k: K1; va: IdMap<K2, V> } | undefined = this.map.get(uuid);
        if (!e) {
            e = {
                k : k1,
                va: new IdMap(this.uuidOf, this.k2Compare, this.vCompare),
            };
            this.map.set(uuid, e);
        }
        e.va.set(k2, v);
    }

    keys(): K1[] {
        return Array.from(this.map.values())
            .map(e => e.k)
            .sort(this.k1Compare);
    }

    values(): V[] {
        return Array.from(this.map.values())
            .map(e => e.va.values())
            .flat()
            .sort(this.vCompare);
    }

    key(uuid: string) {
        return this.map.get(uuid)?.k;
    }

    value(uuid: string): IdMap<K2, V> | undefined {
        return this.map.get(uuid)?.va;
    }
}

export class IdMapMapMap<K1, K2, K3, V> {
    private readonly map: Map<string, { k: K1; va: IdMapMap<K2, K3, V> }> = new Map<string, { k: K1, va: IdMapMap<K2, K3, V> }>();
    private readonly uuidOf: (o: any) => string;
    private readonly k1Compare: (a: K1, b: K1) => number;
    private readonly k2Compare: (a: K2, b: K2) => number;
    private readonly k3Compare: (a: K3, b: K3) => number;
    private readonly vCompare: (a: V, b: V) => number;

    constructor(uuidOf: (o: any) => string, k1Compare: (a: K1, b: K1) => number, k2Compare: (a: K2, b: K2) => number, k3Compare: (a: K3, b: K3) => number, vCompare: (a: V, b: V) => number) {
        this.uuidOf    = uuidOf;
        this.k1Compare = k1Compare;
        this.k2Compare = k2Compare;
        this.k3Compare = k3Compare;
        this.vCompare  = vCompare;
    }

    get(k1: K1): IdMapMap<K2, K3, V> | undefined {
        return this.value(this.uuidOf(k1));
    }

    getget(k1: K1, k2: K2): IdMap<K3, V> | undefined {
        return this.getByUuid(this.uuidOf(k1), k2);
    }

    getgetget(k1: K1, k2: K2, k3: K3): V[] | undefined {
        return this.getByUuidUuid(this.uuidOf(k1), this.uuidOf(k2), k3);
    }

    getByUuid(uuid: string, k2: K2): IdMap<K3, V> | undefined {
        return this.map.get(uuid)?.va?.get(k2);
    }

    getByUuidUuid(uuid1: string, uuid2: string, k3: K3): V[] | undefined {
        return this.map.get(uuid1)?.va?.getByUuid(uuid2, k3);
    }

    set(k1: K1, k2: K2, k3: K3, v: V) {
        const uuid: string                                    = this.uuidOf(k1);
        let e: { k: K1; va: IdMapMap<K2, K3, V> } | undefined = this.map.get(uuid);
        if (!e) {
            e = {
                k : k1,
                va: new IdMapMap(this.uuidOf, this.k2Compare, this.k3Compare, this.vCompare),
            };
            this.map.set(uuid, e);
        }
        e.va.set(k2, k3, v);
    }

    keys(): K1[] {
        return Array.from(this.map.values())
            .map(e => e.k)
            .sort(this.k1Compare);
    }

    values(): V[] {
        return Array.from(this.map.values())
            .map(e => e.va.values())
            .flat()
            .sort(this.vCompare);
    }

    key(uuid: string) {
        return this.map.get(uuid)?.k;
    }

    value(uuid: string): IdMapMap<K2, K3, V> | undefined {
        return this.map.get(uuid)?.va;
    }
}
