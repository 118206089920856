<script setup lang="ts">
const props = defineProps({
  modelValue: {
    type    : Boolean,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const toggleExpanded = () => {
  emit('update:modelValue', !props.modelValue);
};
</script>

<template>
  <div @click="toggleExpanded" class="focus:outline-none">
    <svg
        :class="{ 'rotate-90': props.modelValue }"
        class="w-9 h-9 transition-transform"
        viewBox="0 0 20 20"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
      <path
          fill-rule="evenodd"
          d="M6.293 13.707a1 1 0 010-1.414L8.586 10l-2.293-2.293a1 1 0 111.414-1.414l3 3a1 1 0 010 1.414l-3 3a1 1 0 01-1.414 0z"
          clip-rule="evenodd"
      />
    </svg>

  </div>
</template>
