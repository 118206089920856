<script setup lang="ts">

import {type PropType} from 'vue';
import {
  CabPeriod,
  type CabReportItem,
  compareDates,
  comparePeriods,
  OnderwerpEnum, OnderwerpEnum_name,
  sameDates,
  samePeriods,
} from '@/base/frontendModel';
import ReportGroupMijlpaal from '@/views/report/ReportGroupMijlpaal.vue';
import ReportGroupKpi from '@/views/report/ReportGroupKpi.vue';
// import ReportItemStrip from '@/views/report/ReportItemStrip.vue';

const props = defineProps({
  onderwerp    : {
    type    : Number as PropType<OnderwerpEnum>,
    required: true,
  },
  reportItems  : {
    type    : Object as PropType<CabReportItem[]>,
    required: true,
  },
  flagOmissions: {
    type    : Boolean,
    required: true,
  },
});

function weDoMijlpalen() {
  return props.onderwerp === OnderwerpEnum.mijlpaal;
}

function allMeetMomenten(): Date[] {
  const allDates: Date[]  = props.reportItems.filter(i => i.isMijlpaal).map(i => i.meetMoment!);
  const uniqDates: Date[] = [...new CustomSet<Date>(sameDates).addAll(allDates)];
  uniqDates.sort(compareDates);
  return uniqDates;
}

function risOfMeetmoment(d: Date) {
  return props.reportItems
      .filter(i => i.isMijlpaal && sameDates(i.meetMoment!, d))
      .sort((a, b) => a.kpi[0].name.toLowerCase().localeCompare(b.kpi[0].name.toLowerCase()));
}

function allPeriods(): CabPeriod[] {
  const allPeriods: CabPeriod[]  = props.reportItems.filter(i => !i.isMijlpaal).map(i => i.period[0]!);
  const uniqPeriods: CabPeriod[] = [...new CustomSet<CabPeriod>(samePeriods).addAll(allPeriods)];
  uniqPeriods.sort(comparePeriods);
  return uniqPeriods;
}

function risOfPeriod(p: CabPeriod) {
  return props.reportItems
      .filter(i => !i.isMijlpaal && samePeriods(i.period[0], p))
      .sort((a, b) => a.kpi[0].name.toLowerCase().localeCompare(b.kpi[0].name.toLowerCase()));
}


//===========================================================================================
class CustomSet<T> extends Set<T> {
  constructor(
      private compareFn: (a: T, b: T) => boolean,
      initialValues?: Iterable<T>,
  ) {
    super(initialValues);
  }

  add(value: T): this {
    if (!this.has(value)) {
      return super.add(value);
    }
    return this;
  }

  addAll(l: T[]): CustomSet<T> {
    l.forEach(t => this.add(t));
    return this;
  }

  has(value: T): boolean {
    return Array.from(this).some(existing => this.compareFn(existing, value));
  }
}

</script>

<template>
  <div class="border-2 border-neutral-400 my-2 p-2">
    <div class="backdrop-brightness-90 font-extrabold w-44 text-center shrink-0">
      {{ OnderwerpEnum_name(onderwerp) }}
    </div>
    <div class="ml-12">
      <ReportGroupMijlpaal
          v-if="weDoMijlpalen()"
          v-for="d in allMeetMomenten()"
          :key="d.getTime()"
          :flag-omissions="flagOmissions"
          :report-items="risOfMeetmoment(d)"
          :date="d"/>
      <ReportGroupKpi
          v-if="!weDoMijlpalen()"
          v-for="p in allPeriods()"
          :key="p.endDate().getTime()"
          :flag-omissions="flagOmissions"
          :report-items="risOfPeriod(p)"
          :period="p"/>
    </div>
  </div>
</template>
