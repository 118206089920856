export const THEME_REMOVE_EMIT = 'theme-remove';
export const THEME_CHANGE_EMIT = 'theme-change';

export const ORGANIZATION_REMOVE_EMIT = 'organization-remove';
export const ORGANIZATION_CHANGE_EMIT = 'organization-change';

export const USER_REMOVE_EMIT = 'user-remove';
export const USER_CHANGE_EMIT = 'user-change';

export const KPI_REMOVE_EMIT = 'kpi-remove';
export const KPI_CHANGE_EMIT = 'kpi-change';
