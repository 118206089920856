type ChangeEntry = { id: string, action: () => Promise<void> };

const QUEUE: ChangeEntry[] = [];
let processing: boolean    = false;

export async function debounce(id: string, action: () => Promise<void>): Promise<void> {
    removeFromQueue(id);
    QUEUE.push({
        id,
        action,
    });
    await processQueue();
}

function removeFromQueue(id: string) {
    const index = QUEUE.findIndex(e => e.id === id);
    if (index !== -1) {
        QUEUE.splice(index, 1);
    }
}

async function processQueue(): Promise<void> {
    if (!processing && QUEUE.length !== 0) {
        processing = true;
        try {
            await QUEUE.shift()!.action();
        } finally {
            processing = false;
            await processQueue();
        }
    }
}
