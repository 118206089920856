<script setup lang="ts">
import {ref} from 'vue';
import {submitNewPassword} from 'supertokens-web-js/recipe/emailpassword';
import ZZC from '@/views/general/ZZC.vue';
import {getAppVersion} from '@/base/frontendConfig';

type ReportState = { error: boolean, text?: string, next?: string };

const newPassword = ref<string>('');
const reportState = ref<ReportState>(makeOk());
let errorTimerId: number | NodeJS.Timeout;

function makeError(text: string, next?: string): ReportState {
  return {
    error: true,
    text,
    next,
  };
}

function makeOk(text?: string, next?: string): ReportState {
  return {
    error: false,
    text,
    next,
  };
}

function setReportState(state: ReportState) {
  clrReportState();
  reportState.value = state;
  if (state.text) {
    errorTimerId = setTimeout(clrReportState, 5000);
  } else {
    clrReportState();
  }
}

function clrReportState() {
  if (reportState.value.next) {
    window.location.assign(reportState.value.next);
  }
  reportState.value = makeOk();
  if (errorTimerId) {
    clearTimeout(errorTimerId);
    errorTimerId = 0;
  }
}

async function onResetPassword() {
  setReportState(await resetPassword());
}

async function resetPassword(): Promise<ReportState> {
  try {
    const answer = await submitNewPassword({
      formFields: [
        {
          id   : 'password',
          value: newPassword.value,
        },
      ],
    });

    switch (answer.status) {
      case 'OK': {
        return makeOk('Je password is opnieuw gezet', '/');
      }
      case 'FIELD_ERROR': {
        const msg = answer.formFields[0].error;
        if (msg === 'Field is not optional') {
          return makeError(`je moet een nieuw wachtwoord invullen`);
        } else if (msg === 'Password must contain at least 8 characters, including a number') {
          return makeError(`hij moet 8 lang zijn met een cijfer`);
        } else if (msg === 'Password must contain at least one number') {
          return makeError(`er moet een cijfer in zitten`);
        } else if (msg === 'Password must contain at least one alphabet') {
          return makeError(`er moet een alfabet letter in zitten`);
        } else {
          console.error(answer);
          return makeError(msg, '/');
        }
      }
      case 'RESET_PASSWORD_INVALID_TOKEN_ERROR': {
        return makeError('deze reset mail is verlopen', '/');
      }
      default:
        console.error(answer);
        return makeError('er is een fout opgetreden', '/');
    }
  } catch (err: any) {
    console.error(err);
    return makeError('er is een fout opgetreden', '/');
  }
}
</script>

<template>
  <div class="z-50 absolute top-[12px] left-[12px] px-2 text-xs text-center">
    {{ getAppVersion() }}
  </div>
  <div class="min-h-screen flex items-center justify-center">
    <div
        @keydown.enter="onResetPassword"
        class="relative flex flex-col w-[440px] mx-auto p-8 bg-white rounded-lg shadow-2xl between space-y-4"
    >
      <ZZC/>
      <div class="text-2xl font-bold text-center">
        Nieuw Wachtwoord Instellen
      </div>

      <div>
        <input
            id="newpassword"
            v-model="newPassword"
            type="password"
            class="mt-1 px-3 py-1 block w-full rounded-md shadow-sm border border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        />
      </div>
      <div class="flex flex-row justify-center items-center w-full mt-3 h-8">
        <Transition
            enter-from-class="opacity-0"
            enter-active-class="transition-opacity duration-300 ease-out"
            enter-to-class="opacity-100"
            leave-from-class="opacity-100"
            leave-active-class="transition-opacity duration-1000 ease-in"
            leave-to-class="opacity-0"
        >
          <div v-if="reportState.text" class="py-1 px-2 text-white"
               :class="reportState.error?'bg-red-600':'bg-green-600'">
            {{ reportState.text }}
          </div>
        </Transition>
      </div>
      <div class="flex items-center">
        <button
            @click="onResetPassword"
            class="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</template>
