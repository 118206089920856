<script setup lang="ts">
import {onMounted, ref} from 'vue';
import {deleteTheme, listThemes, updateTheme} from '@/base/callBackend';
import {CabTheme, uuidOf} from '@/base/frontendModel';
// noinspection ES6UnusedImports - usages in <template> are not properly detected!
import {THEME_CHANGE_EMIT, THEME_REMOVE_EMIT} from '@/base/cabConstants';
import ThemeStrip from '@/views/admin/ThemeStrip.vue';
import PlusButton from '@/views/general/PlusButton.vue';

const allThemes = ref<CabTheme[]>([]);

onMounted(async () => {
  allThemes.value = await listThemes();
});

//=====================================================================================
async function onThemeRemove(theme: CabTheme) {
  await deleteTheme(theme);
  allThemes.value = allThemes.value.filter((o) => uuidOf(o) !== uuidOf(theme));
}

async function onThemeAdd() {
  const newTheme = new CabTheme();
  await updateTheme(newTheme);
  allThemes.value = [newTheme, ...allThemes.value];
}

async function onThemeChange(theme: CabTheme) {
  const uuid = uuidOf(theme);
  await updateTheme(theme);
  allThemes.value = allThemes.value.map((o) =>
      uuidOf(o) === uuid ? theme : o,
  );
}
//=====================================================================================
</script>

<template>
  <div class="flex flex-col flex-1 mx-28 my-5">
    <div class="flex flex-row flex-1 mb-1">
      <PlusButton @click="onThemeAdd()"/>
      <div class="text-2xl flex-1 ml-2">Themas</div>
    </div>
    <ThemeStrip
        v-for="theme in allThemes"
        :key="uuidOf(theme)"
        :theme="theme"
        v-on:[THEME_REMOVE_EMIT]="onThemeRemove"
        v-on:[THEME_CHANGE_EMIT]="onThemeChange"
    />
  </div>
</template>
