<script setup lang="ts">
import {ElMessage, ElMessageBox, ElPopconfirm} from 'element-plus';
import {backup, csvExport, devEraseDatabase, restore, syncReports} from '@/base/callBackend';
import {generateDateId} from '@/base/frontendModel';

async function onDownloadCsv() {
  const tag = await askFor('CSV Download');
  if (tag) {
    const response = await csvExport();
    if (!response.ok) {
      const data = await new Response(response.body).json();
      ElMessage({
        message: `Failed: ${data.message}`,
        type   : 'error',
      });
    } else {
      await downloadFile(response, `zzc-${generateDateId()}-${tag}.csv`);
    }
  }
}

async function onDownloadDb() {
  const tag = await askFor('Download Database Dump');
  if (tag) {
    const response = await backup();
    await downloadFile(response, `zzc-${generateDateId()}-${tag}.json`);
  }
}

async function onVerifyDatabase() {
  const response = await syncReports();
  ElMessage({
    message: `Database completely synced (${response})`,
    type   : 'success',
  });
}

async function onEraseDb() {
  await devEraseDatabase();
}

async function onUploadDb(shift: boolean) {
  const input         = document.createElement('input');
  input.type          = 'file';
  input.style.display = 'none';
  input.addEventListener('change', async (event: Event) => {
    const target = event.target as HTMLInputElement;
    const file   = target.files?.[0] ?? null;
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      const result = await restore(formData);
      const body   = await jsonBodyFromResponse(result);
      ElMessage({
        message: `Database completely replaced and restored (${body.message})${shift ? '' : ', reloading in 2 sec...'}`,
        type   : 'success',
      });
      if (!shift) {
        await sleep(2000);
        window.location.reload();
      }
    }
  });
  document.body.appendChild(input);
  input.click();
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

async function downloadFile(response: Response, filename: string) {
  if (!response.ok) {
    ElMessage({
      message: `Failed: ${response.statusText}`,
      type   : 'error',
    });
  } else {
    const blob: Blob  = await response.blob();
    const url: string = URL.createObjectURL(blob);
    const link        = document.createElement('a');
    link.href         = url;
    link.download     = filename;
    link.click();
    URL.revokeObjectURL(url);

    const l: string | null = response.headers.get('content-length');
    const msg: string      = l ? `Downloaded ${Math.floor(((+l) / 1024))} kb` : 'Download done';
    ElMessage({
      message: `${msg}`,
      type   : 'success',
    });
  }
}

async function askFor(title: string) {
  try {
    const {value} = await ElMessageBox.prompt(`Geef een tag:`, title, {
      confirmButtonText: 'OK',
      cancelButtonText : 'Cancel',
      inputPattern     : /^[-A-Za-z0-9_]*$/,
      inputErrorMessage: `Tagss bestaan slechts uit letters, cijfers, '-' en '_'`,
      inputPlaceholder : 'download',
    });
    return value ? value : 'download';
  } catch (error) {
    return undefined;
  }
}

async function jsonBodyFromResponse(res: Response): Promise<any> {
  let result = '';
  if (res.body != null) {
    const reader  = res.body.getReader();
    const decoder = new TextDecoder();
    while (true) {
      const {
              done,
              value,
            } = await reader.read();
      if (done) {
        break;
      }
      result += decoder.decode(value);
    }
  }
  return JSON.parse(result);
}

async function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

</script>

<template>
  <div class="flex justify-center items-center mt-32">
    <div class="flex flex-col space-y-4">

      <div class="flex flex-col space-y-4 justify-center items-center p-5 border-transparent border-2">
        <button
            class="w-full bg-green-600 hover:bg-green-700 text-white py-2 px-10 rounded cursor-pointer"
            @click="onDownloadCsv"
        >
          Download CSV Export
        </button>

        <button
            class="w-full bg-green-600 hover:bg-green-700 text-white py-2 px-10 rounded cursor-pointer"
            @click="onDownloadDb"
        >
          Download Database Dump
        </button>

        <button
            class="w-full bg-green-600 hover:bg-green-700 text-white py-2 px-10 rounded cursor-pointer"
            @click="onVerifyDatabase"
        >
          Verify Database
        </button>

      </div>

      <div class="relative flex flex-col space-y-4 justify-center items-center p-5 border-orange-500 border-2">

        <el-popconfirm
            title="Alle info in de huidige database gaat verloren. Weet je het zeker?"
            width="450px"
            @confirm="onEraseDb"
            confirm-button-text="JA"
            cancel-button-text="Stop"
            confirm-button-type="danger"
            :hide-icon="true"
        >
          <template #reference>
            <button class="w-full bg-green-600 hover:bg-orange-500 text-white py-2 px-10 mt-0 rounded cursor-pointer">
              Erase Database
            </button>
          </template>
        </el-popconfirm>

        <el-popconfirm
            title="Alle info in de huidige database gaat verloren. Weet je het zeker?"
            width="450px"
            @confirm="onUploadDb"
            confirm-button-text="JA"
            cancel-button-text="Stop"
            confirm-button-type="danger"
            :hide-icon="true"
        >
          <template #reference>
            <button class="w-full bg-green-600 hover:bg-orange-500 text-white py-2 px-10 rounded cursor-pointer">
              Upload Dump and Overwrite Database
            </button>
          </template>
        </el-popconfirm>

        <span
            class="absolute top-[-30px] left-[140px] bg-orange-500 text-white text-sm px-4 py-0.5 rounded cursor-default">
          Danger Zone
        </span>
      </div>
    </div>
  </div>
</template>
