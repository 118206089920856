import {getBackendUrl} from '@/base/frontendConfig';
import {CabKPI, CabOrganization, CabReportItem, CabTheme, CabUser, clrInSync, JSONparse, JSONstringify} from '@/base/frontendModel';
import {plainToInstance} from 'class-transformer';
import {debounce} from '@/base/debouncer';

///////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
export async function syncUsers() {
    return await backend('GET', `/api/admin/user/sync`, undefined);
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////

export async function listKpis() {
    const rawList = await backend('GET', `/api/admin/kpi`, undefined);
    return plainToInstance(CabKPI, rawList as CabKPI[]);
}

export async function deleteKpi(kpi: CabKPI) {
    clrInSync(kpi);
    await backend('DELETE', `/api/admin/kpi/delete`, kpi);
}

export async function updateKpi(kpi: CabKPI) {
    clrInSync(kpi);
    await debounce(kpi.uuid,
        async () => await backend('PATCH', `/api/admin/kpi/change`, kpi),
    );
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
export async function listUsers() {
    const rawList = await backend('GET', `/api/admin/user`, undefined);
    return plainToInstance(CabUser, rawList as CabUser[]);
}

export async function deleteUser(user: CabUser) {
    clrInSync(user);
    await backend('DELETE', `/api/admin/user/delete`, user);
}

export async function updateUser(user: CabUser) {
    clrInSync(user);
    await debounce(
        user.uuid, async () => await backend('PATCH', `/api/admin/user/change`, user),
    );
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
export async function listOrganizations() {
    const rawList = await backend('GET', `/api/admin/organization`, undefined);
    return plainToInstance(CabOrganization, rawList as CabOrganization[]);
}

export async function deleteOrganization(organization: CabOrganization) {
    clrInSync(organization);
    await backend('DELETE', `/api/admin/organization/delete`, organization);
}

export async function updateOrganization(organization: CabOrganization) {
    clrInSync(organization);
    await debounce(organization.uuid,
        async () => await backend('PATCH', `/api/admin/organization/change`, organization),
    );
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
export async function listThemes() {
    const rawList = await backend('GET', `/api/admin/theme`, undefined);
    return plainToInstance(CabTheme, rawList as CabTheme[]);
}

export async function deleteTheme(theme: CabTheme) {
    clrInSync(theme);
    await backend('DELETE', `/api/admin/theme/delete`, theme);
}

export async function updateTheme(theme: CabTheme) {
    clrInSync(theme);
    await debounce(theme.uuid,
        async () => await backend('PATCH', `/api/admin/theme/change`, theme),
    );
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
export async function listAdminItems() {
    return plainToInstance(CabReportItem, await backend('GET', `/api/admin/reportitem`, undefined));
}

export async function updateAdminItem(ri: CabReportItem) {
    clrInSync(ri);
    await debounce(ri.uuid,
        async () => await backend('PATCH', `/api/admin/reportitem/change`, ri),
    );
}

export async function readAdminItem(ri: CabReportItem): Promise<CabReportItem> {
    clrInSync(ri);
    const answer: CabReportItem = await backend('POST', `/api/admin/reportitem/read`, ri);
    return plainToInstance(CabReportItem, answer);
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
export async function listMyOrganizations() {
    return plainToInstance(CabOrganization, await backend('GET', `/api/report/organizations`, undefined));
}

export async function listMyReportItems() {
    return plainToInstance(CabReportItem, await backend('GET', `/api/report/reportitem`, undefined));
}

export async function updateReportItem(item: CabReportItem) {
    clrInSync(item);
    await debounce(item.uuid,
        async () => await backend('PATCH', `/api/report/reportitem/change`, item),
    );
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
export async function getMe() {
    return await backend('GET', `/api/me`, undefined);
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
export async function devEraseDatabase() {
    return await backend('GET', `/api/dev/erase-database`, undefined);
}

export async function devCurrentDate(d: Date) {
    return await backend('POST', `/api/dev/current-date`, {date: d});
}

export async function todo(): Promise<string> {
    return await backend('GET', `/api/dev/todo`, undefined);
}

export async function backup(): Promise<Response> {
    return await backendRaw('GET', `/api/dev/backup`);
}

export async function restore(body: any): Promise<Response> {
    return await backendRaw('POST', `/api/dev/restore`, body);
}

export async function syncReports(): Promise<any> {
    return await backend('GET', `/api/dev/syncreports`, undefined);
}

export async function csvExport(): Promise<Response> {
    return await backendRaw('GET', `/api/dev/csvExport`);
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
async function backend(method: string, path: string, body?: any) {
    const response = await backendRaw(method, path, body ? JSONstringify(body) : undefined, body ? 'application/json' : undefined);
    const txt      = await response.text();
    if (!response.ok) {
        throw new Error(`backend call did not succeed: ${method} ${path} (${response.status}: ${txt})`);
    }
    return await JSONparse(txt);
}

async function backendRaw(method: string, path: string, body?: any, contentType?: string) {
    if (!path.startsWith(`/`)) {
        throw new Error(`backend calls should start with '/': ${method} ${path}`);
    }
    const headers: string[][] = [];
    const init: RequestInit   = {
        method : method,
        headers: headers,
    };
    if (body) {
        init.body = body;
    }
    if (contentType) {
        headers.push(['Content-Type', contentType]);
    }
    const url      = getBackendUrl();
    const fullPath = `${url}${path}`;
    return await fetch(fullPath, init);
}
