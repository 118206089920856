<script setup lang="ts">
import {type PropType, ref, watch} from 'vue';
import {
  CabKPI,
  type CabReportItem,
  enum_values,
  KpiStatusEnum,
  KpiStatusEnum_name,
  KpiStatusPrognoseEnum,
  KpiStatusPrognoseEnum_name,
  MijlpaalEnum,
  MijlpaalEnum_name,
  MijlpaalPrognoseEnum,
  MijlpaalPrognoseEnum_name,
  StatusEnum,
  StatusEnum_color,
  StatusEnum_name,
  WaardeTypeEnum,
} from '@/base/frontendModel';
import {updateReportItem} from '@/base/callBackend';
import 'element-plus/dist/index.css';
import {ElInput, ElOption, ElSelect} from 'element-plus';
import WaardeInvul from '@/views/general/WaardeInvul.vue';
import Waarde from '@/views/general/Waarde.vue';
import KpiIcon from '@/views/general/KpiIcon.vue';

//=================================================================================================
const props = defineProps({
  reportitem   : {
    type    : Object as PropType<CabReportItem>,
    required: true,
  },
  flagOmissions: {
    type    : Boolean,
    required: true,
  },
});

const kpi                  = ref<CabKPI>(props.reportitem.kpi[0]);
const theValue             = ref<number | MijlpaalEnum | undefined>(props.reportitem.reportedValue);
const theKpiStatus         = ref<KpiStatusEnum | undefined>(props.reportitem.kpiStatus);
const thePrognose          = ref<number | MijlpaalPrognoseEnum | undefined>(props.reportitem.prognose);
const theKpiStatusPrognose = ref<KpiStatusPrognoseEnum | undefined>(props.reportitem.kpiStatusPrognose);

//=================================================================================================
watch(theValue, (v) => {
  reportedValueChanged(v);
});
watch(theKpiStatus, (v) => {
  kpiStatusValueChanged(v);
});
watch(thePrognose, (v) => {
  prognoseChanged(v);
});
watch(theKpiStatusPrognose, (v) => {
  kpiStatusPrognoseChanged(v);
});

//=================================================================================================
async function reportedValueChanged(v: number | MijlpaalEnum | undefined) {
  if (v !== theValue.value || v !== props.reportitem.reportedValue) {
    theValue.value                 = v;
    props.reportitem.reportedValue = v;
    await onChange();
  }
}

async function kpiStatusValueChanged(v: KpiStatusEnum | undefined) {
  if (v !== theKpiStatus.value || v !== props.reportitem.kpiStatus) {
    theKpiStatus.value         = v;
    props.reportitem.kpiStatus = v;
    await onChange();
  }
}

async function prognoseChanged(v: number | MijlpaalPrognoseEnum | undefined) {
  if (v !== thePrognose.value || v !== props.reportitem.prognose) {
    thePrognose.value         = v;
    props.reportitem.prognose = v;
    await onChange();
  }
}

async function kpiStatusPrognoseChanged(v: KpiStatusPrognoseEnum | undefined) {
  if (v !== theKpiStatusPrognose.value || v !== props.reportitem.kpiStatusPrognose) {
    theKpiStatusPrognose.value         = v;
    props.reportitem.kpiStatusPrognose = v;
    await onChange();
  }
}

function keyupOnTheValue(event: any) {
  if (event.key === 'Backspace' || event.key === 'Delete') {
    theValue.value = undefined;
    event.originalTarget.blur();
  }
}

function keyupOnTheKpiStatus(event: any) {
  if (event.key === 'Backspace' || event.key === 'Delete') {
    theKpiStatus.value = undefined;
    event.originalTarget.blur();
  }
}

function keyupOnThePrognose(event: any) {
  if (event.key === 'Backspace' || event.key === 'Delete') {
    thePrognose.value = undefined;
    event.originalTarget.blur();
  }
}

function keyupOnTheKpiStatusPrognose(event: any) {
  if (event.key === 'Backspace' || event.key === 'Delete') {
    theKpiStatusPrognose.value = undefined;
    event.originalTarget.blur();
  }
}

async function onChange() {
  await updateReportItem(props.reportitem);
}

//=================================================================================================
function open_or_overdue() {
  return props.reportitem.status === StatusEnum.open || props.reportitem.status === StatusEnum.overdue;
}

function isMijlpaal() {
  return props.reportitem?.isMijlpaal;
}

function isIndienbaar() {
  return props.reportitem.isSubmittable();
}

//=================================================================================================
function dynColor() {
  return StatusEnum_color(props.reportitem.status);
}

function dynClassForLabelColumn() {
  return 'shrink-0 w-36 mr-4 py-1 text-right';
}

function omissionColor(v: number | MijlpaalEnum | string | undefined) {
  return (props.flagOmissions || flagOmissionsLocal.value) && (v === undefined || v === '') ? 'outline outline-3 outline-red-500' : '';
}

const flagOmissionsLocal = ref(false);

function showOmissions() {
  flagOmissionsLocal.value = true;
}

function hideOmissions() {
  flagOmissionsLocal.value = false;
}

//=================================================================================================
</script>

<template>
  <div class="flex flex-col justify-start items-stretch mb-2 pr-2" :class="dynColor()"
       @mouseenter="showOmissions"
       @mouseleave="hideOmissions"
  >
    <div class="flex flex-row justify-start items-center">
      <KpiIcon :kpi="kpi" class="ml-2"/>
      <div class="font-bold flex-wrap">
        {{ kpi.name }}
      </div>

      <div class="flex-1">
        <!--filler-->
      </div>
      <div class="cursor-default">
        {{ isIndienbaar() ? '✅' : '' }}
      </div>

      <div class="backdrop-brightness-90 font-extrabold my-1 mx-1 w-32 text-center shrink-0">
        {{ StatusEnum_name(reportitem.status) }}
      </div>
    </div>

    <div class="flex flex-col space-y-1 ml-20 mb-2">
      <!--+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++-->
      <div v-if="isMijlpaal() && reportitem.showReportedValue()"
           class="flex flex-row justify-start items-center">
        <div :class="dynClassForLabelColumn()" v-if="open_or_overdue()">
          ik rapporteer
        </div>
        <div :class="dynClassForLabelColumn()" v-else>
          ik rapporteerde
        </div>

        <div class="font-bold w-72">
          <el-select
              :class="omissionColor(theValue)"
              v-model="theValue"
              placeholder="kies een optie"
              style="min-width: 210px"
              :disabled="!open_or_overdue()"
              @keyup="keyupOnTheValue($event)"
          >
            <el-option
                v-for="item in enum_values(MijlpaalEnum)"
                :key="item"
                :label="MijlpaalEnum_name(item)"
                :value="item"
            />
          </el-select>
        </div>

      </div>
      <!--+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++-->
      <div v-if="!isMijlpaal() && (reportitem.showReportedValue() || reportitem.showKpiStatus())"
           class="flex flex-row justify-start items-center">

        <template v-if="reportitem.showReportedValue()">
          <div :class="dynClassForLabelColumn()" v-if="open_or_overdue()">
            ik rapporteer
          </div>
          <div :class="dynClassForLabelColumn()" v-else>
            ik rapporteerde
          </div>

          <div class="font-bold">
            <WaardeInvul
                :class="omissionColor(theValue)"
                :waarde-type="kpi.waardeType"
                :waarde="theValue"
                @update-waarde="reportedValueChanged($event)"
                :disabled="!open_or_overdue()"
            />
          </div>
        </template>

        <template v-if="reportitem.showKpiStatus()">
          <div :class="dynClassForLabelColumn()">
            met status
          </div>
          <div class="font-bold w-72">
            <el-select
                :class="omissionColor(theKpiStatus)"
                v-model="theKpiStatus"
                placeholder="kies een optie"
                style="min-width: 210px"
                :disabled="!open_or_overdue()"
                @keyup="keyupOnTheKpiStatus($event)"
            >
              <el-option
                  v-for="item in enum_values(KpiStatusEnum)"
                  :key="item"
                  :label="KpiStatusEnum_name(item)"
                  :value="item"
              />
            </el-select>
          </div>
        </template>

      </div>
      <!--+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++-->
      <div class="flex flex-row justify-start items-center"
           v-if="reportitem.showPrognose() || reportitem.showKpiStatusPrognose()">
        <template v-if="reportitem.showPrognose()">
          <div v-if="isMijlpaal()"
               class="flex flex-row justify-start items-start">
            <div :class="dynClassForLabelColumn()">
              prognose
            </div>
            <div class="font-bold w-72">
              <el-select
                  :class="omissionColor(thePrognose)"
                  v-model="thePrognose"
                  placeholder="kies een optie"
                  style="min-width: 270px"
                  :disabled="!open_or_overdue()"
                  @keyup="keyupOnThePrognose($event)"
              >
                <el-option
                    v-for="item in enum_values(MijlpaalPrognoseEnum)"
                    :key="item"
                    :label="MijlpaalPrognoseEnum_name(item)"
                    :value="item"
                />
              </el-select>
            </div>
          </div>

          <div v-if="!isMijlpaal()"
               class="flex flex-row justify-start items-baseline">
            <div :class="dynClassForLabelColumn()">
              prognose
            </div>
            <WaardeInvul
                class="font-bold"
                :class="omissionColor(thePrognose)"
                :waarde-type="kpi.waardeType"
                :waarde="thePrognose"
                @update-waarde="prognoseChanged($event)"
                :disabled="!open_or_overdue()"
            />
          </div>
        </template>

        <template v-if="reportitem.showKpiStatusPrognose()">
          <div :class="dynClassForLabelColumn()">
            status prognose
          </div>

          <div class="font-bold w-72">
            <el-select
                :class="omissionColor(theKpiStatusPrognose)"
                v-model="theKpiStatusPrognose"
                placeholder="kies een optie"
                style="min-width: 270px"
                :disabled="!open_or_overdue()"
                @keyup="keyupOnTheKpiStatusPrognose($event)"
            >
              <el-option
                  v-for="item in enum_values(KpiStatusPrognoseEnum)"
                  :key="item"
                  :label="KpiStatusPrognoseEnum_name(item)"
                  :value="item"
              />
            </el-select>
          </div>
        </template>

      </div>
      <!--+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++-->
      <div class="flex flex-row justify-start items-center"
           v-if="reportitem.showMitigatie()">
        <div :class="dynClassForLabelColumn()">
          mitigatie
        </div>
        <div class="font-bold w-full">
          <el-input
              :class="omissionColor(reportitem.mitigatie)"
              v-model="reportitem.mitigatie"
              @input="onChange()"
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 5 }"
              :disabled="!open_or_overdue()"
          />
        </div>
      </div>
      <!--+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++-->
      <div class="flex flex-row justify-start items-center">
        <div :class="dynClassForLabelColumn()">
          opmerkingen
        </div>
        <div class="font-bold w-full">
          <el-input
              v-model="reportitem.opmerking"
              @input="onChange()"
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 5 }"
              :disabled="!open_or_overdue()"
          />
        </div>
      </div>
      <!--+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++-->
      <div class="flex flex-row justify-start items-center"
           v-if="!!kpi.omschrijving">
        <div :class="dynClassForLabelColumn()">
          omschrijving
        </div>
        <div class="bg-white/50 px-2 w-full">
          {{ kpi.omschrijving }}
        </div>
      </div>
      <!--+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++-->
      <div class="flex flex-row justify-start items-center"
           v-if="reportitem.showDoelwaarde(false)">
        <div :class="dynClassForLabelColumn()">
          doelwaarde
        </div>
        <div class="bg-white/50 px-2">
          <Waarde
              :waarde="reportitem.doelwaarde"
              :waarde-type="kpi.waardeType"/>
        </div>
      </div>
      <!--+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++-->
      <div class="flex flex-row justify-start items-center"
           v-if="reportitem.showUitkeringsbedrag(false)">
        <div :class="dynClassForLabelColumn()">
          uitkeringsbedrag
        </div>
        <div class="bg-white/50 px-2">
          <Waarde
              :waarde="reportitem.uitkeringsbedrag"
              :waarde-type="WaardeTypeEnum.bedrag"
          />
        </div>
      </div>
      <!--+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++-->
      <div class="flex flex-row justify-start items-center"
           v-if="reportitem.showVecozoId(false)">
        <div :class="dynClassForLabelColumn()">
          vecozo id
        </div>
        <div class="bg-white/50 px-2">
          {{ reportitem.vecozoId }}
        </div>
      </div>
      <!--+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++-->
    </div>
  </div>
</template>

<style></style>
