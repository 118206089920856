import {createRouter, createWebHistory} from 'vue-router';
import {getMe} from '@/base/callBackend';
import * as Session from 'supertokens-web-js/recipe/session';
import type {CabMe} from '@/base/frontendModel';
import LoginDialog from '@/authentication/LoginDialog.vue';
import ReportLanding from '@/views/report/ReportLanding.vue';
import AdminLanding from '@/views/admin/AdminLanding.vue';
import KPIList from '@/views/admin/KPIList.vue';
import ReportList from '@/views/report/ReportList.vue';
import StatusList from '@/views/admin/StatusList.vue';
import UserList from '@/views/admin/UserList.vue';
import OrganizationList from '@/views/admin/OrganizationList.vue';
import ThemeList from '@/views/admin/ThemeList.vue';
import PasswordResetDialog from '@/authentication/PasswordResetDialog.vue';
import AdminPage from '@/views/admin/AdminPage.vue';


export enum KpiSelectEnum {
    onlyMilestones,
    allOthers
}

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes : [
        {
            path     : '/',
            name     : 'login',
            component: LoginDialog,
        },
        {
            path     : '/auth/reset-password',
            name     : 'password reset',
            component: PasswordResetDialog,
        },
        {
            path     : '/admin',
            name     : 'admin',
            component: AdminLanding,
            meta     : {requires: 'admin'},
            redirect : '/admin/thema',
            children : [
                {
                    path     : 'thema',
                    component: ThemeList,
                    meta     : {requires: 'admin'},
                },
                {
                    path     : 'organization',
                    component: OrganizationList,
                    meta     : {requires: 'admin'},
                },
                {
                    path     : 'user',
                    component: UserList,
                    meta     : {requires: 'admin'},
                },
                {
                    path     : 'milestone',
                    component: KPIList,
                    props    : {kpiSelect: KpiSelectEnum.onlyMilestones},
                    meta     : {requires: 'admin'},
                },
                {
                    path     : 'kpi',
                    component: KPIList,
                    props    : {kpiSelect: KpiSelectEnum.allOthers},
                    meta     : {requires: 'admin'},
                },
                {
                    path     : 'status',
                    component: StatusList,
                    meta     : {requires: 'admin'},
                },
                {
                    path     : 'admin',
                    component: AdminPage,
                    meta     : {requires: 'admin'},
                },
            ],
        },
        {
            path     : '/report',
            name     : 'report',
            component: ReportLanding,
            meta     : {requires: 'report'},
            redirect : '/report/report',
            children : [
                {
                    path     : 'report',
                    component: ReportList,
                    meta     : {requires: 'report'},
                },
            ],
        },
        {
            path    : '/:pathMatch(.*)*',
            redirect: '/',
        },
    ],
});
router.beforeEach(checkPermission);

export async function getMeInfo(): Promise<CabMe | undefined> {
    if (!(await Session.doesSessionExist())) {
        return undefined;
    } else {
        return await getMe();
    }
}

async function checkPermission(to: any, from: any, next: any) {
    const requires = to.meta.requires;
    if (!requires) {
        next();
    } else {
        const meInfo = await getMeInfo();
        if (!meInfo) {
            next('/');
        } else if (!meInfo.permissions?.includes(requires)) {
            next('/');
        } else {
            next();
        }
    }
}

export default router;
