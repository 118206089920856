<script setup lang="ts">
import {type PropType, ref, watch} from 'vue';
import {
  CabKPI,
  type CabReportItem,
  enum_values,
  KpiStatusEnum,
  KpiStatusEnum_name,
  KpiStatusPrognoseEnum,
  KpiStatusPrognoseEnum_name,
  MijlpaalEnum,
  MijlpaalEnum_name,
  MijlpaalPrognoseEnum,
  MijlpaalPrognoseEnum_name,
  renderCabPeriod,
  renderTerseDate,
  StatusEnum,
  StatusEnum_color,
  StatusEnum_name,
  WaardeTypeEnum,
} from '@/base/frontendModel';
import {readAdminItem, updateAdminItem} from '@/base/callBackend';
import WaardeInvul from '@/views/general/WaardeInvul.vue';
import {ElInput, ElOption, ElSelect} from 'element-plus';

//===================================================================================================================
const props = defineProps({
  reportitem: {
    type    : Object as PropType<CabReportItem>,
    required: true,
  },
});

const kpi                  = ref<CabKPI>(props.reportitem.kpi[0]);
const theValue             = ref<number | MijlpaalEnum | undefined>(props.reportitem.reportedValue);
const theKpiStatus         = ref<KpiStatusEnum | undefined>(props.reportitem.kpiStatus);
const thePrognose          = ref<number | MijlpaalPrognoseEnum | undefined>(props.reportitem.prognose);
const theKpiStatusPrognose = ref<KpiStatusPrognoseEnum | undefined>(props.reportitem.kpiStatusPrognose);

//experiment
const selectedIndex = ref<number>(0);
watch(selectedIndex, (i) => console.log(`NEW INDEX ${i}`));

//=================================================================================================
watch(theValue, (v) => {
  reportedValueChanged(v);
});
watch(theKpiStatus, (v) => {
  kpiStatusValueChanged(v);
});
watch(thePrognose, (v) => {
  prognoseChanged(v);
});
watch(theKpiStatusPrognose, (v) => {
  kpiStatusPrognoseChanged(v);
});

//=================================================================================================
async function reportedValueChanged(v: number | MijlpaalEnum | undefined) {
  if (v !== theValue.value || v !== props.reportitem.reportedValue) {
    theValue.value                 = v;
    props.reportitem.reportedValue = v;
    await onChange();
  }
}

async function kpiStatusValueChanged(v: KpiStatusEnum | undefined) {
  if (v !== theKpiStatus.value || v !== props.reportitem.kpiStatus) {
    theKpiStatus.value         = v;
    props.reportitem.kpiStatus = v;
    await onChange();
  }
}

async function prognoseChanged(v: number | MijlpaalPrognoseEnum | undefined) {
  if (v !== thePrognose.value || v !== props.reportitem.prognose) {
    thePrognose.value         = v;
    props.reportitem.prognose = v;
    await onChange();
  }
}

async function kpiStatusPrognoseChanged(v: KpiStatusPrognoseEnum | undefined) {
  if (v !== theKpiStatusPrognose.value || v !== props.reportitem.kpiStatusPrognose) {
    theKpiStatusPrognose.value         = v;
    props.reportitem.kpiStatusPrognose = v;
    await onChange();
  }
}

function keyupOnTheValue(event: any) {
  if (event.key === 'Backspace' || event.key === 'Delete') {
    theValue.value = undefined;
    event.originalTarget.blur();
  }
}

function keyupOnTheKpiStatus(event: any) {
  if (event.key === 'Backspace' || event.key === 'Delete') {
    theKpiStatus.value = undefined;
    event.originalTarget.blur();
  }
}

function keyupOnThePrognose(event: any) {
  if (event.key === 'Backspace' || event.key === 'Delete') {
    thePrognose.value = undefined;
    event.originalTarget.blur();
  }
}

function keyupOnTheKpiStatusPrognose(event: any) {
  if (event.key === 'Backspace' || event.key === 'Delete') {
    theKpiStatusPrognose.value = undefined;
    event.originalTarget.blur();
  }
}

async function onChange() {
  await updateAdminItem(props.reportitem);
}

//===================================================================================================================
async function doelwaardeChanged(newValue: number) {
  props.reportitem.doelwaarde = newValue;
  await onChange();
}

async function uitkeringsbedragChanged(newValue: number) {
  props.reportitem.uitkeringsbedrag = newValue;
  await onChange();
}

async function toOpen() {
  props.reportitem.status = StatusEnum.open;
  await onChange();
  props.reportitem.status = (await readAdminItem(props.reportitem)).status;
}

async function toAccepted() {
  props.reportitem.status = StatusEnum.accepted;
  await onChange();
}

async function toSubmitted() {
  props.reportitem.status = StatusEnum.submitted;
  await onChange();
}

//===================================================================================================================
function isMijlpaal() {
  return props.reportitem?.isMijlpaal;
}

//===================================================================================================================
function dynColor() {
  return StatusEnum_color(props.reportitem.status);
}

function labelClass(first: boolean) {
  const w = first ? 'w-44' : 'w-28';
  return `text-xs text-right ${w} mr-2`;
}

function renderMoment() {
  if (isMijlpaal()) {
    return renderTerseDate(props.reportitem.meetMoment!);
  } else {
    return renderCabPeriod(props.reportitem.period[0]);
  }
}
</script>

<template>
  <div class="flex flex-row items-start" :class="dynColor()">

    <!--DATE-->
    <div class="backdrop-brightness-90 font-extrabold text-center my-1 mx-1 w-32">
      {{ renderMoment() }}
    </div>

    <div class="flex flex-col w-full my-1 pr-4">

      <!--ROW 1 : mijlpaal-->
      <div class="flex flex-row items-center w-full my-1"
           v-if="isMijlpaal()">

        <!--mijlpaal: gerapporteerde waarde-->
        <div class="flex flex-row items-center"
             v-if="reportitem.showReportedValue()">
          <div :class="labelClass(true)">
            gerapporteerde waarde
          </div>
          <div class="font-bold">
            <div class="font-bold">
              <el-select
                  v-model="theValue"
                  placeholder="kies een optie"
                  style="min-width: 270px"
                  @keyup="keyupOnTheValue($event)"
              >
                <el-option
                    v-for="item in enum_values(MijlpaalEnum)"
                    :key="item"
                    :label="MijlpaalEnum_name(item)"
                    :value="item"
                />
              </el-select>
            </div>
          </div>
        </div>
        <div class="flex flex-row items-center"
             v-else>
          <div :class="labelClass(true)">
          </div>
          <div class="text-sm text-gray-400" style="min-width: 270px">
            geen waarde, alleen prognose
          </div>
        </div>

        <!--mijlpaal: prognose-->
        <div class="flex flex-row items-center"
             v-if="reportitem.showPrognose()">
          <div :class="labelClass(false)">
            prognose
          </div>
          <div class="font-bold">
            <div class="">
              <el-select
                  v-model="thePrognose"
                  placeholder="kies een optie"
                  style="min-width: 270px"
                  @keyup="keyupOnThePrognose($event)"
              >
                <el-option
                    v-for="item in enum_values(MijlpaalPrognoseEnum)"
                    :key="item"
                    :label="MijlpaalPrognoseEnum_name(item)"
                    :value="item"
                />
              </el-select>
            </div>
          </div>
        </div>
      </div>

      <!--ROW 1 : KPI-->
      <div class="flex flex-row items-center w-full my-1"
           v-if="!isMijlpaal() && (reportitem.showReportedValue() || reportitem.showPrognose() || reportitem.showDoelwaarde(true))">

        <!--kpi: gerapporteerde waarde-->
        <div class="flex flex-row items-center"
             v-if="reportitem.showReportedValue()">
          <div :class="labelClass(true)">
            gerapporteerde waarde
          </div>
          <div class="font-bold">
            <div class="font-bold text-right">
              <div class="">
                <WaardeInvul
                    :waarde-type="kpi.waardeType"
                    :waarde="theValue"
                    style="min-width: 270px"
                    @update-waarde="reportedValueChanged($event)"
                />
              </div>
            </div>
          </div>
        </div>

        <!--kpi: prognose-->
        <div class="flex flex-row items-center"
             v-if="reportitem.showPrognose()">
          <div :class="labelClass(false)">
            prognose
          </div>
          <div class="font-bold">
            <div class="text-right">
              <div class="">
                <WaardeInvul
                    class="font-bold"
                    :waarde-type="kpi.waardeType"
                    :waarde="thePrognose"
                    style="min-width: 270px"
                    @update-waarde="prognoseChanged($event)"
                />
              </div>
            </div>
          </div>
        </div>

        <!--kpi: doelwaarde-->
        <div class="flex flex-row items-center"
             v-if="reportitem.showDoelwaarde(true)">
          <div :class="labelClass(false)">
            doelwaarde
          </div>
          <div class="font-bold">
            <WaardeInvul
                class=""
                :waarde="reportitem?.doelwaarde"
                :waarde-type="kpi.waardeType"
                style="min-width: 270px"
                @update-waarde="doelwaardeChanged($event)"
            />
          </div>
        </div>
      </div>

      <!--ROW 2 : KPI-->
      <div class="flex flex-row items-center w-full my-1"
           v-if="!isMijlpaal() && (reportitem.showKpiStatus() || reportitem.showKpiStatusPrognose())">

        <!--KPI: gerapporteerde status-->
        <div class="flex flex-row items-center"
             v-if="reportitem.showKpiStatus()">
          <div :class="labelClass(true)">
            gerapporteerde status
          </div>
          <div class="font-bold">
            <div class="font-bold">
              <el-select
                  v-model="theKpiStatus"
                  placeholder="kies een optie"
                  style="min-width: 270px"
                  @keyup="keyupOnTheKpiStatus($event)"
              >
                <el-option
                    v-for="item in enum_values(KpiStatusEnum)"
                    :key="item"
                    :label="KpiStatusEnum_name(item)"
                    :value="item"
                />
              </el-select>
            </div>
          </div>
        </div>

        <!--KPI: prognose status-->
        <div class="flex flex-row items-center"
             v-if="reportitem.showKpiStatusPrognose()">
          <div :class="labelClass(false)">
            status prognose
          </div>
          <div class="font-bold">
            <div class="">
              <el-select
                  v-model="theKpiStatusPrognose"
                  placeholder="kies een optie"
                  style="min-width: 270px"
                  @keyup="keyupOnTheKpiStatusPrognose($event)"
              >
                <el-option
                    v-for="item in enum_values(KpiStatusPrognoseEnum)"
                    :key="item"
                    :label="KpiStatusPrognoseEnum_name(item)"
                    :value="item"
                />
              </el-select>
            </div>
          </div>
        </div>
      </div>

      <!--ROW 3-->
      <div class="flex flex-row items-center w-full my-1"
           v-if="reportitem.showUitkeringsbedrag(true) || reportitem.showVecozoId(true)">

        <!--uitkeringsbedrag-->
        <div class="flex flex-row items-center"
             v-if="reportitem.showUitkeringsbedrag(true)">
          <div :class="labelClass(true)">
            uitkeringsbedrag
          </div>

          <div class="font-bold">
            <WaardeInvul
                class=""
                :waarde="reportitem?.uitkeringsbedrag"
                :waarde-type="WaardeTypeEnum.bedrag"
                style="min-width: 270px"
                @update-waarde="uitkeringsbedragChanged($event)"
            />
          </div>
        </div>

        <!--vecozo id-->
        <div class="flex flex-row items-center"
             v-if="reportitem.showVecozoId(true)">
          <div :class="labelClass(false)">
            vecozo id
          </div>
          <div class="font-bold">
            <input
                class="px-2 py-1 border-1 w-full"
                v-model="reportitem.vecozoId"
                type="text"
                style="min-width: 270px"
                @input="onChange"
            />
          </div>
        </div>

      </div>

      <!--ROW 4:opmerking-->
      <div class="flex flex-row items-center w-full my-1">
        <div :class="labelClass(true)">
          opmerking
        </div>

        <div class="flex-1"
             title="opmerking">
          <el-input
              v-model="reportitem.opmerking"
              @input="onChange()"
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 5 }"
          />
        </div>
      </div>

      <!--ROW 5: mitigatie-->
      <div class="flex flex-row items-center w-full my-1"
           v-if="reportitem.showMitigatie()">
        <div :class="labelClass(true)">
          mitigatie
        </div>

        <div class="flex-1"
             title="mitigatie">
          <el-input
              v-model="reportitem.mitigatie"
              @input="onChange()"
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 5 }"
          />
        </div>
      </div>

    </div>

    <!--STATUS-->
    <div class="flex flex-col items-center">
      <div class="backdrop-brightness-90 font-extrabold text-center my-1 mx-1 w-44 mb-4">
        {{ StatusEnum_name(reportitem.status) }}
      </div>

      <button
          class="bg-green-600 hover:bg-green-700 text-white text-xs font-bold text-center rounded cursor-pointer py-1 px-5 my-1 mx-1 w-32"
          v-if="reportitem.status === StatusEnum.open || reportitem.status === StatusEnum.overdue"
          @click="toSubmitted"
      >
        Indienen
      </button>

      <button
          class="bg-green-600 hover:bg-green-700 text-white text-xs font-bold text-center rounded cursor-pointer py-1 px-5 my-1 mx-1 w-32"
          v-if="reportitem.status === StatusEnum.submitted"
          @click="toOpen"
      >
        Heropen
      </button>

      <button
          class="bg-green-600 hover:bg-green-700 text-white text-xs font-bold text-center rounded cursor-pointer py-1 px-5 my-1 mx-1 w-32"
          v-if="reportitem.status === StatusEnum.submitted"
          @click="toAccepted"
      >
        Accepteer
      </button>

      <button
          class="bg-green-300 hover:bg-green-700 text-white text-xs font-bold text-center rounded cursor-pointer py-1 px-5 my-1 mx-1 w-32"
          v-if="reportitem.status === StatusEnum.accepted"
          @click="toSubmitted"
      >
        Herindienen
      </button>

    </div>

  </div>
</template>
