<script setup lang="ts">
import {type PropType, ref, watch} from 'vue';
import {ElInput} from 'element-plus';
import {formatWaarde, parseWaarde, type WaardeTypeEnum} from '@/base/frontendModel';

const props = defineProps({
  waarde    : Number,
  waardeType: {
    type    : Number as PropType<WaardeTypeEnum>,
    required: true,
  },
});

const emits = defineEmits<{
  (e: 'update-waarde', value: number | undefined): void;
}>();

const waardeText = ref<string>(formatWaarde(props.waarde, props.waardeType));
const waardeRef  = ref<number | undefined>(props.waarde);

watch(waardeText, () => {
  waardeRef.value = parseWaarde(waardeText.value);
  emits('update-waarde', waardeRef.value);
});

function formatter(value: string): string {
  return formatWaarde(parseWaarde(value), props.waardeType);
}

function parser(value: string): string {
  return formatWaarde(parseWaarde(value), props.waardeType);
}

</script>

<template>
  <div class="waarde-invul w-36">

    <el-input
        v-model="waardeText"
        type="text"
        :formatter="formatter"
        :parser="parser"
    />

  </div>
</template>

<!--suppress CssUnusedSymbol -->
<style>
.waarde-invul .el-input__inner {
  @apply text-right;
}
</style>

