<script setup lang="ts">
import {onMounted, ref, watch} from 'vue';
import {listAdminItems} from '@/base/callBackend';
import {CabKPI, CabOrganization, CabReportItem, CabTheme, StatusEnum, uuidOf} from '@/base/frontendModel';
import {ElCheckbox, ElOption, ElSelect, ElSkeleton, ElSkeletonItem} from 'element-plus';
import StatusKpiStrip from '@/views/admin/StatusKpiStrip.vue';
import {IdMapMapMap} from '@/base/idMap';

const mapmapmap                = ref<IdMapMapMap<CabOrganization, CabTheme, CabKPI, CabReportItem>>();
const loading                  = ref(true);
const allOrganizations         = ref<CabOrganization[]>([]);
const selectedOrganizationUuid = ref<string>();
const selectedOrganization     = ref<CabOrganization>();
const allThemes                = ref<CabTheme[]>();
const selectedThemeUuid        = ref<string>();
const selectedTheme            = ref<CabTheme>();
const allKpis                  = ref<CabKPI[]>();
const showFuture               = ref(false);

onMounted(async () => {
  mapmapmap.value = listToMapmapmap(await listAdminItems());
  loading.value   = false;
});

watch(mapmapmap, () => {
  if (mapmapmap.value) {
    allOrganizations.value = Array.from(mapmapmap.value.keys());
  }
});
watch(allOrganizations, () => {
  if (0 < allOrganizations.value.length) {
    selectedOrganizationUuid.value = uuidOf(allOrganizations.value[0]);
  }
});
watch(selectedOrganizationUuid, () => {
  selectedOrganization.value = allOrganizations.value.find(o => uuidOf(o) === selectedOrganizationUuid.value);
});
watch(selectedOrganization, () => {
  allThemes.value = selectedOrganization.value?.themes;
});
watch(allThemes, () => {
  if (allThemes.value) {
    selectedThemeUuid.value = uuidOf(allThemes.value[0]);
  }
});
watch(selectedThemeUuid, () => {
  selectedTheme.value = allThemes.value?.find(t => uuidOf(t) === selectedThemeUuid.value);
});
watch([selectedTheme, showFuture], () => {
  allKpis.value = getAllKpis();
});

function listToMapmapmap(list: CabReportItem[]): IdMapMapMap<CabOrganization, CabTheme, CabKPI, CabReportItem> {
  const mapmapmap = new IdMapMapMap<CabOrganization, CabTheme, CabKPI, CabReportItem>(
      (o: any) => uuidOf(o),
      (a, b) => a.compare(b),
      (a, b) => a.compare(b),
      (a, b) => a.compare(b),
      (a, b) => a.compare(b),
  );
  for (const item of list) {
    mapmapmap.set(item.organization[0], item.theme[0], item.kpi[0], item);
  }
  return mapmapmap;
}

function getAllKpis(): CabKPI[] {
  if (!mapmapmap.value || !selectedOrganization.value || !selectedTheme.value) {
    return [];
  } else {
    const kpis = mapmapmap.value?.getget(selectedOrganization.value, selectedTheme.value)?.keys();
    if (!kpis) {
      return [];
    } else if (showFuture.value) {
      return kpis;
    } else {
      return kpis?.filter(kpi => mapmapmap.value
          ?.getget(selectedOrganization.value!, selectedTheme.value!)
          ?.get(kpi)!
          .some(ri => ri.status !== StatusEnum.future));
    }
  }
}

function allReportItemsFor(kpi: CabKPI): CabReportItem[] {
  if (!mapmapmap.value || !selectedOrganization.value || !selectedTheme.value) {
    return [];
  }
  const list: CabReportItem[] = mapmapmap.value
      .getgetget(selectedOrganization.value, selectedTheme.value, kpi)!;
  if (!list) {
    return [];
  } else if (showFuture.value) {
    return list;
  } else {
    return list.filter(ri => ri.status !== StatusEnum.future);
  }
}

</script>

<template>
  <div class="flex flex-col flex-1 mx-28 my-5">
    <div class="flex flex-row flex-1 items-center">
      <div class="flex flex-row items-center my-3">
        <div class="text-base">
          Rapportages voor
        </div>
        <div v-if="loading">
          <el-skeleton animated>
            <template #template>
              <el-skeleton-item class="w-52 h-8 mx-1"/>
            </template>
          </el-skeleton>
        </div>
        <el-select v-else v-model="selectedOrganizationUuid" class="w-52 mx-1">
          <el-option
              v-for="org in allOrganizations"
              :key="uuidOf(org)"
              :value="uuidOf(org)"
              :label="org.name"
          />
        </el-select>
        <div class="text-base mx-1">
          met thema
        </div>
        <div v-if="loading">
          <el-skeleton animated>
            <template #template>
              <el-skeleton-item class="w-52 h-8 mx-1"/>
            </template>
          </el-skeleton>
        </div>
        <el-select v-else v-model="selectedThemeUuid" class="w-52 mx-1">
          <el-option
              v-for="theme in allThemes"
              :key="uuidOf(theme)"
              :value="uuidOf(theme)"
              :label="theme.name"
          />
        </el-select>
      </div>
      <div class="flex-1">
        <!--spacer-->
      </div>
      <el-checkbox
          class="mr-2"
          v-model="showFuture"
          label="toon toekomst"
          border
      />
    </div>
    <StatusKpiStrip
        v-for="kpi in allKpis"
        :key="uuidOf(kpi)"
        :kpi="kpi"
        :reportitems="allReportItemsFor(kpi)"
    />
  </div>
</template>
