import {createApp} from 'vue';
import App from './App.vue';
import router from './base/cabRouter';
import './assets/main.css';
import SuperTokens from 'supertokens-web-js';

import {SuperTokensConfigFrontend} from '@/base/frontendConfig';

SuperTokens.init(SuperTokensConfigFrontend);

const app = createApp(App);

app.use(router);
app.mount('#app');
