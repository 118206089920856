<script setup lang="ts">
import {RouterView} from 'vue-router';
import {onMounted} from 'vue';
import {getAppName, getAppVersion, getBackendUrl, getFrontendUrl, getSupertokensCoreUrl} from '@/base/frontendConfig';

onMounted(()=>{
  console.log(`INFO : App Name           = ${getAppName()}`);
  console.log(`INFO : App Version        = ${getAppVersion()}`);
  console.log(`INFO : FrontendUrl        = ${getFrontendUrl()}`);
  console.log(`INFO : BackendUrl         = ${getBackendUrl()}`);
  console.log(`INFO : SupertokensCoreUrl = ${getSupertokensCoreUrl()}`);
})
</script>

<template>
  <RouterView/>
</template>
