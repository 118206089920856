<script setup lang="ts">
import {nextTick, type PropType, ref} from 'vue';
import {THEME_CHANGE_EMIT, THEME_REMOVE_EMIT} from '@/base/cabConstants';
import type {CabTheme} from '@/base/frontendModel';
import DeleteButton from '@/views/general/DeleteButton.vue';

const emits = defineEmits([THEME_REMOVE_EMIT, THEME_CHANGE_EMIT]);
const props = defineProps({
  theme: {
    type    : Object as PropType<CabTheme>,
    required: true,
  },
});

const theme     = ref<CabTheme>(props.theme);
const nameField = ref<HTMLInputElement | null>(null);
const nameInfo  = {
  field    : nameField,
  isEditing: ref<boolean>(false),
};

//==========================================
function startEditing(info: any) {
  info.isEditing.value = true;
  nextTick(() => nextTick(() => info.field.value.focus()));
}

function stopEditing(info: any) {
  info.isEditing.value = false;
  info.field.value.blur();
}

function onDelete() {
  emits(THEME_REMOVE_EMIT, theme.value);
}

function onChange() {
  emits(THEME_CHANGE_EMIT, theme.value);
}

function dynInputClass(info: any) {
  return info.isEditing.value
         ? `bg-white border-gray-300 rounded-md shadow-xl`
         : `bg-yellow-100`;
}
</script>

<template>
  <div
      class="flex flex-row items-center w-full font-medium m-0.5 p-1 bg-yellow-100 text-gray-900"
  >
    <div class="flex-1 px-3 py-1" title="thema naam">
      <input
          class="mt-1 mr-1 px-2 py-1 border-1 w-full"
          :class="dynInputClass(nameInfo)"
          type="text"
          ref="nameField"
          v-model="theme.name"
          @input="onChange"
          @click="startEditing(nameInfo)"
          @focus="startEditing(nameInfo)"
          @keyup.enter="stopEditing(nameInfo)"
          @blur="stopEditing(nameInfo)"
          placeholder="thema naam"
      />
    </div>

    <DeleteButton @click="onDelete()"/>
  </div>
</template>
